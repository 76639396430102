import { TextField } from "@mui/material";
import React, { Component } from "react";
import {
  Row,
  Col,
  CardBody,
  Button,
  Card,
} from "reactstrap";
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import JoditEditor from "jodit-react";
import axios from "axios";
import NotificationAlert from "react-notification-alert";
import DefaultImg from "./DefaultImage";

class Blogform extends Component {

  constructor(props) {
    super(props);
    this.state = { 
        title_tr: '',
        title_en: '',
        desc_tr: '',
        desc_en: '',
        file1: null,
        file2: null,
        image1: '',
        image2: '',
        href: this.props.update ? `update-blog/${this.props.id}` : 'add-blog',
        switch_lang: true,
        upload_img: false,
    };
    this.editor = React.createRef(null);
  }

  componentDidMount(){
    if(this.props.update){
      axios.get(`../api/auth/blog/${this.props.id}`)
      .then(res => {
        this.setState(res.data.data)
      })
    }
  }

  handleInputchange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onFileChange = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0]
    });
  }

  handleSwitchChange = (e) => {
    this.setState({
      switch_lang: e === 'tr' ? true : false
    })
  }

  handleImageUpload = (e) => {
    this.setState({
      upload_img: e
    })
  }

  handleSuccessImage = (e) => {
    this.setState({
      upload_img: e.modal
    });
  }

  handleSubmit = () => {
    let data = new FormData;
    data.append('title_tr', this.state.title_tr);
    data.append('title_en', this.state.title_en);
    data.append('desc_tr', this.state.desc_tr);
    data.append('desc_en', this.state.desc_en);
    data.append('image1', this.state.file1);
    data.append('image2', this.state.file2);
    axios.post(`../api/auth/${this.state.href}`, data)
    .then(res => {
      this.props.handleResponse(res.data.values)
      if(!this.props.update){
        this.setState({
          title_tr: '',
          title_en: '',
          desc_tr: '',
          desc_en: '',
          file: null,
        })
      }
      this.handleNotificate({
        message: res.data.msg,
        type: res.data.status ? 'success' : 'danger'
      });
    })
  }

  handleNotificate = (s) => {
    s.message.forEach(item => {
      this.refs.notify.notificationAlert({
        place: 'br',
        message: item,
        type: s.type,
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 5
      });
    });
  }

  render(){
    return (
        <>
          <NotificationAlert ref="notify" zIndex={9999}/>
          <CardBody>
            <Row>
              <Col className="mb-2 mt-2" md={9}>
                <Col className="d-flex" md={12}>
                  <Col md="6" className="mb-2 mt-2 p-0 pr-1">
                    <TextField
                      label={'Başlık 🇹🇷'}
                      id="filled-size-small"
                      size="small"
                      className="mb-2"
                      value={this.state.title_tr}
                      onChange={this.handleInputchange}
                      name="title_tr"
                      fullWidth
                    />
                  </Col>
                  <Col md="6" className="mb-2 mt-2 p-0 pl-1">
                    <TextField
                      label={'Başlık 🇺🇸'}
                      id="filled-size-small"
                      size="small"
                      className="mb-2"
                      value={this.state.title_en}
                      onChange={this.handleInputchange}
                      name="title_en"
                      fullWidth
                    />
                  </Col>
                </Col>
                <Col md={12} className="d-flex">
                  <div className="language-switcher">
                    <button type="onclick" onClick={this.handleSwitchChange.bind(this, 'tr')} className={this.state.switch_lang ? "active" : ""}>🇹🇷</button>
                    <button type="onclick" onClick={this.handleSwitchChange.bind(this, 'en')} className={!this.state.switch_lang ? "active" : ""}>🇺🇸</button>
                  </div>
                  {/* <RichTextEditor/> */}
                  <JoditEditor
                    tabIndex={5}
                    darkMode={true}
                    ref={this.editor}
                    value={this.state.switch_lang ? this.state.desc_tr : this.state.desc_en}
                    config={{readonly: false}}
                    onBlur={newContent => this.state.switch_lang ? this.setState({desc_tr: newContent}) : this.setState({desc_en: newContent})} 
                    onChange={newContent => {}}
                  />
                </Col>
                  <Button
                    className="btn-fill ml-3" 
                    type="submit"
                    onClick={this.handleImageUpload.bind(this, true)}
                  >
                    Resim Yükle
                  </Button>
              </Col>
              <Col className="px-3 mb-2 d-block" md={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="file-input-label">Fotoğraf seçmek için tıklayın</Form.Label>
                  {this.state.image1 === '' ? 
                    <DefaultImg
                      width={1800}
                      height={600}
                    />
                  :
                    <Image 
                      src={`https://mancgames.com/storage/img/${this.state.image1}`}
                      className="file-input-example"
                      fluid 
                    />
                  }
                  <Form.Control 
                    type="file"
                    onChange={this.onFileChange}
                    name="file1"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="file-input-label">Fotoğraf seçmek için tıklayın</Form.Label>
                  {this.state.image2 === '' ? 
                    <DefaultImg
                      width={1200}
                      height={600}
                    />
                  :
                    <Image 
                      src={`https://mancgames.com/storage/img/${this.state.image2}`}
                      className="file-input-example"
                      fluid 
                    />
                  }
                  <Form.Control 
                    type="file"
                    onChange={this.onFileChange}
                    name="file2"
                  />
                </Form.Group>
              </Col>
              <Button
                className="btn-fill ml-3" 
                type="submit"
                onClick={this.handleSubmit}
              >
                Kaydet
              </Button>
            </Row>
          </CardBody>
          <Uploadwindow 
            modal={this.state.upload_img} 
            handleCancel={(e) => {
              this.handleImageUpload(e);
            }}
          />
        </>
    );
  }
  
}
export default Blogform;


class Uploadwindow extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      alt: '',
      width: '100%',
      height: 'auto',
      url: '',
      file: null,
    }
  }
  handleCancel = (e) => {
      this.setState({
          url: '',
          file: null, 
      })
    this.props.handleCancel(e)
  }

  handleSubmit = (e) => {
    let data = new FormData();
    data.append('image', this.state.file)
  //   data.append('alt', this.state.alt)
  //   data.append('width', this.state.width)
  //   data.append('height', this.state.height)
    axios.post('../api/auth/image-upload', data)
    .then(res => {
      if(res.data.status){
        this.setState({
          url: res.data.image,
        })
      }
    })
  }
  handleFileChange =(e) => {
    this.setState({
      file: e.target.files[0]
    })
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  render(){
    return (
      <Col md="12" className={(this.props.modal ? 'opened' : '' )+ " t-modal-body"}>
        <Card className=" transaction-modal">
            <CardBody className='mt-4 d-flex'>
              <Col md={12}>
                {/* <TextField
                  label={'Başlık'}
                  id="filled-size-small"
                  size="small"
                  className="mb-2"
                  name="alt"
                  value={this.state.alt}
                  onChange={this.handleChange}
                  fullWidth
                />
                <TextField
                  label={'Width'}
                  id="filled-size-small"
                  size="small"
                  className="mb-2"
                  name="width"
                  value={this.state.width}
                  onChange={this.handleChange}
                  fullWidth
                />
                <TextField
                  label={'Height'}
                  id="filled-size-small"
                  size="small"
                  className="mb-2"
                  name="height"
                  value={this.state.height}
                  onChange={this.handleChange}
                  fullWidth
                /> */}
                <TextField
                    size="small"
                    className="mb-2"
                    name="image"
                    type="file"
                    onChange={this.handleFileChange}
                    fullWidth
                  /> 
                  <TextField
                      label={'URL'}
                      id="filled-size-small"
                      size="small"
                      className="mb-2"
                      name="height"
                      value={this.state.url}
                      onChange={this.handleChange}
                      placeholder="Yükelenen Resmin URL'i"
                      fullWidth
                  /> 
                <Col md={12} className="d-flex p-0">
                  <Button 
                    className="btn-fill w-100" 
                    type="onclick"
                    onClick={this.handleSubmit}
                  >
                    Kaydet
                  </Button>
                  <Button 
                    className="btn-fill w-100" 
                    type="onclick"
                    onClick={this.handleCancel.bind(this, false)}
                  >
                    Kapat
                  </Button>
                </Col>
              </Col>
            </CardBody>
        </Card>
    </Col>)
  }
  
}