import axios from "axios";
import React, {Component} from "react";
import Send from "./Add";
import NotificationAlert from "react-notification-alert";
import {
  Card,
  CardHeader,
  Row,
  Col,
} from "reactstrap";

class Update extends Component {
  constructor(props) {
    super(props);
    this.state = 
    { 
      values: [],
      name: this.props.name,
      headers: this.props.headers,
      dbValues: this.props.db_values,
      widths: this.props.widths,
      getHref: this.props.getHref,
      updateHref: this.props.updateHref,
      formHref: this.props.formHref,
      imgValue: this.props.imgValue,
      index: 0,
    };
    axios.get(this.state.getHref)
    .then(res => {
      this.setState({
        values : res.data.values
      })
    })
  }
  
  handleClick = (s) => {
    axios.get(this.state.getHref)
    .then(res => {
      this.setState({
        values: ['']
      })
      this.setState({
        values: res.data.values
      })
    });
    this.handleNotificate(s);
  }
  handleNotificate = (s) => {
    s.message.forEach(item => {
      this.refs.notify.notificationAlert({
        place: 'br',
        message: item,
        type: s.type,
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 5
      });
    });
  }
  render(){
    return (
      <>
        <NotificationAlert ref="notify" zIndex={9999}/>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
              <CardHeader>
                  <h4 className="title">{this.state.name}</h4>
                </CardHeader>
                <Send 
                  handleClick={this.handleClick}
                  values={this.state.values}
                  dbValues={this.state.dbValues}
                  updateHref={this.state.updateHref + 1}
                  formHref={this.state.updateHref + 1}
                  edit={true}
                  headers={this.state.headers}
                  elementId={1}
                  imgValue={this.state.imgValue}
                  getHref={this.state.getHref}
                  index={this.state.index}
                >
                </Send>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default Update;
