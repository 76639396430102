
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import React, {Component} from 'react';
import Iconsarray from './Allicons';


class IconDrops extends Component {
   
    handleSelect = (i, e) => {
        if(this.props.itemid){
            let href = '../api/auth/update-icon/' + this.props.itemid,
                data = new FormData;
            data.append('icon', e.target.id);
            axios.post(href, data);
        }
    }
    render(){
        return (
            <>
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className="btn-fill" >
                        icon
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    {Iconsarray.map((item, index) => {
                        return (
                            <Dropdown.Item 
                                key={index}
                                onClick={this.handleSelect.bind(this, item.icon)} 
                                id={item.icon}
                                itemid={this.props.itemid}
                                onChange={this.props.onChange}
                            >
                                <i className={'tim-icons sidebar-icons text-center ' + item.icon} />
                            </Dropdown.Item>)
                    })}
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }
}
export default IconDrops;