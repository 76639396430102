import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Col,
  } from "reactstrap";
class Jobdetails extends Component {

    render(){
        return (
            <>
                <Col lg="12" md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h3"><b>{this.props.mainjob.name_tr} Detay</b></CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Col className="job-inf">
                                <p className="job-title">Başlık 🇹🇷 </p>
                                <p className="job-explain">{this.props.mainjob.name_tr}</p>
                            </Col>

                            <Col className="job-inf">
                                <p className="job-title">Başlık 🇺🇸 </p>
                                <p className="job-explain">{this.props.mainjob.name_en}</p>
                            </Col>
                            <Col className="job-inf">
                                <p className="job-title">Açıklama 🇹🇷 </p>
                                <p className="job-explain">{this.props.mainjob.desc_tr}</p>
                            </Col>
                            <Col className="job-inf">
                                <p className="job-title">Açıklama 🇺🇸 </p>
                                <p className="job-explain">{this.props.mainjob.desc_en}</p>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
            </>
          );
    }
  
}
export default Jobdetails;