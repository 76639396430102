import React, { Component } from "react";
import axios from 'axios';
import Send from "./Add";
import Details from "./Details";
import NotificationAlert from "react-notification-alert";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

class FormMain extends Component {

  constructor(props) {
    super(props);
    axios.get(this.props.getHref)
    .then(res => {
      if(res.data.status == false){
        this.setState({
          values: []
        });
      }
      else{
        this.setState({
          values: res.data.values
        });
        if(res.data.values[res.data.values.length-1].background){
          this.setState({
            background: res.data.values[res.data.values.length-1].background
          })
        }
      }
    });
  }
  state = { 
    values: [], 
    background: '',
  };

  handleClick = (s) => {
    axios.get(this.props.getHref)
    .then(res => {
      this.setState({
        values: []
      })
      this.setState({
        values: res.data.values
      })
    });
    this.handleNotificate(s);
  }
  handleNotificate = (s) => {
    s.message.forEach(item => {
      this.refs.notify.notificationAlert({
        place: 'br',
        message: item,
        type: s.type,
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 5
      });
    });
    
  }
  render(){
    return (
      <>
      <NotificationAlert ref="notify" zIndex={9999}/>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="title">{this.props.name}</h4>
                </CardHeader>
                <Send 
                  dbValues={this.props.db_values}
                  formHref={this.props.formHref}
                  edit={false}
                  headers={this.props.headers}
                  getHref={this.props.getHref}
                  handleClick={this.handleClick}
                  values={this.state.values}
                  imgValue={this.props.imgValue}
                  background={this.state.background}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Details
                    dbValues={this.props.db_values}
                    headers={this.props.headers}
                    widths={this.props.widths}
                    updateHref={this.props.updateHref}
                    getHref={this.props.getHref}
                    values={this.state.values}
                    handleClick={this.handleClick}
                    imgValue={this.props.imgValue}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  
}
export default FormMain;
