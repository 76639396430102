import React, { Component } from 'react';
import {
    Row,
  } from "reactstrap";
  import Accordion from '@mui/material/Accordion';
  import AccordionDetails from '@mui/material/AccordionDetails';
  import AccordionSummary from '@mui/material/AccordionSummary';
  import Applicant from './Applicant';

class Userdetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            exp: 'expanded-',
            userid: this.props.userid,
        }
    }
    handleChange = (i, e, isExpanded) => {
        if(isExpanded){
            this.setState({
                exp: 'expanded-' + i
            });
        }
        else {
            this.setState({
                exp: 'expanded-'
            });
        }
    }
    render(){
        return(
            <>
                <tr key={this.props.index}>
                    <td className="apply-td" colSpan={4}>
                        <Accordion 
                            expanded={this.state.exp === ('expanded-' + this.props.index)} 
                            onChange={this.handleChange.bind(this, this.props.index)
                        }>
                            <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <td className="mw-30">{this.props.item['İsim Soyisim']}</td>
                                <td className="mw-30">{this.props.item['Başvurduğu Pozisyon']}</td>
                                <td className="mw-30">{this.props.item['Başvuru Tarihi']}</td>
                                <td className="mw-10">buttons</td>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Applicant
                                        userid={this.props.item.id}
                                    />
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </td>
                </tr>
            </>
        );
    }
}
export default Userdetails;