/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Nav, NavItem, NavLink } from "reactstrap";

function Footer() {
  return (
    <footer className="footer">
      <Container fluid>
        <Nav>
          <NavItem>
            <NavLink href="https://www.creative-tim.com/blog?ref=bdr-user-archive-footer">
              MANC Games
            </NavLink>
          </NavItem>
        </Nav>
        <div className="copyright">
          © 2021 made with by 
          <a
            href="https://mancgames.com/"
            target="_blank"
          >
            m.a.n.c Games
          </a> for a better web.
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
