import routes from 'routes';

import NotificationAlert from "react-notification-alert";
import React, { Component, useState} from 'react';
import DnDList from 'react-dnd-list';
import IconDrops from '../components/Icon/Iconarray';
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
  } from "reactstrap";
import Form from 'react-bootstrap/Form';
import axios from 'axios';

const MyList = (props) => {
  const [check, setCheck] = useState(null);
  const onCheck = (e) => {
    if(e.target.value === 1){
      setCheck({
        check: true
      });
    }
    if(e.target.value === 0){
      setCheck({
        check: false
      });
    }
    let theValue = e.target.value,
        href = '../api/auth/update-active/' + e.target.id,
        data = new FormData();
        theValue = theValue === 1 ? 0 : 1; 
    data.append('active', theValue);
    axios.post(href, data)
  }
 
  const items = [];
  routes.forEach(item => {
    if(item.line >= 0) {
      items.push(
        <div key={item.id}>
          <ul className="drag-list d-flex">
            <li className="col-4">{item.name}</li>
            <li className="col-4 text-center ml-5">
              <Form.Check 
                  type="switch"
                  id={item.id}
                  onChange={onCheck}
                  value={item.active}
                  defaultChecked={item.active === 1 ? true : false}
              />
            </li>
            <li className="text-center col-4">
              <IconDrops 
                itemid={item.id}
              />
            </li>
          </ul>
        </div>
      )
    }
  });
  const [list, setList] = useState(items);
  return (
    <ul className="w-100 drag-list">
      <DnDList
        items={list}
        itemComponent={props.itemComponent}
        setList={setList}
      />
    </ul>
  )
}

class TableList extends Component {
  constructor(props){
    super(props);
    this.state = { values: [] };
  }
  Item = props => {
    this.state.values[props.index] = props;
    const dnd = props.dnd;
    if(props.itemInDrag){
      if(!(props.listInDrag)){
        let newlines = [];
        this.state.values.map(element => {
          newlines.push([element.item.key, element.index])
        })
        let data = new FormData(),
            href = '../api/auth/update-line';
        data.append('newlines', JSON.stringify(newlines));
        axios.post(href, data);
      }
    }
    return (
      <li
        style={{ ...dnd.item.styles, ...dnd.handler.styles }}
        className={dnd.item.classes}
        ref={dnd.item.ref}
        {...dnd.handler.listeners}
        value={this.state.values[props]} 
      >
        {props.item}
      </li>
    )
  }
  
  render() {
    
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">SAYFALAR</CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">Sayfa</th>
                      <th className="text-center">Aktiflik</th>
                      <th className="text-center">Icon</th>
                    </tr>
                  </thead>
                </Table>
                <MyList
                  itemComponent={this.Item}
                />
                <NotificationAlert ref="notify" zIndex={9999}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}


export default TableList;