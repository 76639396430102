import React, { Component } from 'react'

class DefaultImg extends Component {
    constructor(props){
        super(props);
        this.state = {
            width: 0,
            height: 0,
            color: this.props.color || '#eaecee',
        }
        this.imgRef = React.createRef(null);
    }
    componentDidMount(){
        let innerwidth = this.imgRef.current.clientWidth;
        this.setWidth(innerwidth);
    }
    setWidth = (w) => {
        setTimeout(() => {
            this.setState({
                width: w
            })
        }, 250);
    }
    render(){
        return(
            <div 
                ref={this.imgRef}
                style={{
                    width: this.props.realwidth ? this.props.realwidth : '100%',
                    height: `${(this.props.height / this.props.width)*this.state.width}px`,
                    background: this.state.color,
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#00000070',
                    position: this.props.position ? this.props.position : 'relative',
                    fontWeight: 500,
                    display: 'flex',
                    fontSize: 'calc(0.2vh + 0.8vw)',
                }}
            >{ `${this.props.width} x ${this.props.height}` }</div>
        )
    }
}
export default DefaultImg