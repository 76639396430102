import axios from "axios";
import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import NotificationAlert from "react-notification-alert";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
const fbcolor = { color: '#3b5998' };
const twcolor = { color: '#1da1f2' };
const incolor = { color: '#c13584' };
const licolor = { color: '#0e76a8' };
const ghcolor = { color: '#333' };


class Contact extends Component {

  constructor(props){
    super(props);
    axios.get('../api/auth/contact')
    .then(res=>{
      this.setState({
        contacts: res.data.values
      })
    })
  }
  state = {
    adress1_tr: "",
    adress1_en: "",
    adress2_tr: "",
    adress2_en: "",
    email: "",
    phone: "",
    location: "",
    facebook: "",
    twitter: "",
    instagram: "",
    linkedin: "",
    github: "",
    contacts: []
  }
  changeValue1 = e => {
    this.setState({
      adress1_tr: e.target.value
    })
  }
  changeValue2 = e => {
    this.setState({
      adress1_en: e.target.value
    })
  }
  changeValue3 = e => {
    this.setState({
      adress2_tr: e.target.value
    })
  }
  changeValue4 = e => {
    this.setState({
      adress2_en: e.target.value
    })
  }
  changeValue5 = e => {
    this.setState({
      email: e.target.value
    })
  }
  changeValue6 = e => {
    this.setState({
      phone: e.target.value
    })
  }
  changeValue7 = e => {
    this.setState({
      location: e.target.value
    })
  }
  changeValue8 = e => {
    this.setState({
      facebook: e.target.value
    })
  }
  changeValue9 = e => {
    this.setState({
      twitter: e.target.value
    })
  }
  changeValue10 = e => {
    this.setState({
      instagram: e.target.value
    })
  }
  changeValue11 = e => {
    this.setState({
      linkedin: e.target.value
    })
  }
  changeValue12 = e => {
    this.setState({
      github: e.target.value
    })
  }
  onSubmit = e => {
    e.preventDefault();
    var formData = new FormData();
    formData.append('adress1_tr', this.state.adress1_tr);
    formData.append('adress1_en', this.state.adress1_en);
    formData.append('adress2_tr', this.state.adress2_tr);
    formData.append('adress2_en', this.state.adress2_en);
    formData.append('email', this.state.email);
    formData.append('phone', this.state.phone);
    formData.append('location', this.state.location);
    formData.append('facebook', this.state.facebook);
    formData.append('twitter', this.state.twitter);
    formData.append('instagram', this.state.instagram);
    formData.append('linkedin', this.state.linkedin);
    formData.append('github', this.state.github);

    axios.post('../api/auth/update-contact/1', formData)
    .then(res => {
      this.setState({
        adress1_tr: '',
        adress1_en: '',
        adress2_tr: '',
        adress2_en: '',
        email: '',
        phone: '',
        location: '',
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: '',
        github: '',
        contacts: res.data.values
      })
    })
    this.handleNotificate();
  }
  handleNotificate = () => {
    this.refs.notify.notificationAlert({
      place: 'br',
      message: 'Başarılı Bir Şekilde Kaydedildi',
      type: 'success',
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 5
    });
  }
  render(){
    return (
      <>
      <NotificationAlert ref="notify" zIndex={9999}/>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="title">İletişim</h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col 
                      md="12"
                      className="mt-auto mb-auto"
                    >
                      <Col className="d-flex">
                        <Col className="mb-md-1 px-1" md="6">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Adres-1 🇹🇷</Form.Label>
                            <Form.Control 
                              as="textarea" 
                              rows={3} 
                              className="form-with-value"
                              value={this.state.adress1_tr}
                              onChange={this.changeValue1}
                              placeholder={this.state.contacts.adress1_tr}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="mb-md-1 px-1 " md="6">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Adres-1 🇺🇸</Form.Label>
                            <Form.Control 
                              as="textarea" 
                              rows={3} 
                              className="form-with-value"
                              value={this.state.adress1_en}
                              onChange={this.changeValue2}
                              placeholder={this.state.contacts.adress1_en}
                            />
                          </Form.Group>
                        </Col>
                      </Col>
                      <Col className="d-flex">
                        <Col className="mb-md-1 px-1" md="6">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Adres-2 🇹🇷</Form.Label>
                            <Form.Control 
                              as="textarea" 
                              rows={3} 
                              className="form-with-value"
                              value={this.state.adress2_tr}
                              onChange={this.changeValue3}
                              placeholder={this.state.contacts.adress2_tr}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="mb-md-1 px-1" md="6">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Adres-2 🇺🇸</Form.Label>
                            <Form.Control 
                              as="textarea" 
                              rows={3} 
                              className="form-with-value"
                              value={this.state.adress2_en}
                              onChange={this.changeValue4}
                              placeholder={this.state.contacts.adress2_en}
                            />
                          </Form.Group>
                        </Col>
                      </Col>
                      <Col 
                        md="12"
                        className="d-flex mb-2"
                      >
                        <Col className="px-0 d-flex" md="12">
                          <Form.Group className="px-1 col-4" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control 
                              className="form-with-value"
                              type="text"
                              value={this.state.email}
                              onChange={this.changeValue5}
                              placeholder={this.state.contacts.email}
                            />
                          </Form.Group>

                          <Form.Group className="px-1 col-4" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Telefon</Form.Label>
                            <Form.Control 
                              className="form-with-value"
                              type="text" 
                              value={this.state.phone}
                              onChange={this.changeValue6}
                              placeholder={this.state.contacts.phone}
                            />
                          </Form.Group>

                          <Form.Group className="px-1 col-4" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Konum</Form.Label>
                            <Form.Control 
                              className="form-with-value"
                              type="text" 
                              value={this.state.location}
                              onChange={this.changeValue7}
                              placeholder={this.state.contacts.location}
                            />
                          </Form.Group>
                        </Col>
                      </Col><Col 
                        md="12"
                        className="d-flex mb-2"
                      >
                        <Col className="px-0 d-flex" md="12">
                          <Form.Group className="px-1" controlId="exampleForm.ControlTextarea1">
                            <Form.Label><i className="fab fa-facebook" style={fbcolor}></i> Facebook</Form.Label>
                            <Form.Control 
                              className="form-with-value"
                              type="text"
                              value={this.state.facebook}
                              onChange={this.changeValue8}
                              placeholder={this.state.contacts.facebook}
                            />
                          </Form.Group>
                          <Form.Group className="px-1" controlId="exampleForm.ControlTextarea1">
                            <Form.Label><i className="fab fa-twitter" style={twcolor}></i> Twitter</Form.Label>
                            <Form.Control 
                              className="form-with-value"
                              type="text"
                              value={this.state.twitter}
                              onChange={this.changeValue9}
                              placeholder={this.state.contacts.twitter}
                            />
                          </Form.Group>
                          <Form.Group className="px-1" controlId="exampleForm.ControlTextarea1">
                            <Form.Label><i className="fab fa-instagram" style={incolor}></i> Instagram</Form.Label>
                            <Form.Control 
                              className="form-with-value"
                              type="text"
                              value={this.state.instagram}
                              onChange={this.changeValue10}
                              placeholder={this.state.contacts.instagram}
                            />
                          </Form.Group>
                          <Form.Group className="px-1" controlId="exampleForm.ControlTextarea1">
                            <Form.Label><i className="fab fa-linkedin" style={licolor}></i> Linkedin</Form.Label>
                            <Form.Control 
                              className="form-with-value"
                              type="text"
                              value={this.state.linkedin}
                              onChange={this.changeValue11}
                              placeholder={this.state.contacts.linkedin}
                            />
                          </Form.Group>
                          <Form.Group className="px-1" controlId="exampleForm.ControlTextarea1">
                            <Form.Label><i className="fab fa-github" style={ghcolor}></i> GitHub</Form.Label>
                            <Form.Control 
                              className="form-with-value"
                              type="text"
                              value={this.state.github}
                              onChange={this.changeValue12}
                              placeholder={this.state.contacts.github}
                            />
                          </Form.Group>
                        </Col>
                      </Col>
                    </Col>
                  </Row>
                  <Button className="btn-fill ml-3" type="oncClick" onClick={this.onSubmit}>
                    Gönder
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  
}
export default Contact;
