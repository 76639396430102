import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Table,
    Row,
    Col,
    Button,
    CardTitle,
  } from "reactstrap";
import axios from 'axios';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material';
const ui_translate = {
    id: 'ID',
    wallet_address: 'Cüzdan Adresi',
    twitter_username: 'Twitter Kullanıcı Adı',
    twitter_id: 'Twitter ID',
    twitter_likes: 'Twitter Like',
    twitter_retweets: 'Twitter RT',
    twitter_follows: 'Twitter Follow',
    telegram_username: 'Telegram Kullanıcı Adı',
    telegram_id: 'Telegram ID',
    telegram_onumber_quest: 'Telegram O\'Numbers Görevi',
    telegram_mancium_quest: 'Telegram Mancium Görevi',
    discord_username: 'Discord Kullanıcı Adı',
    discord_id: 'Discord ID',
    discord_quest: 'Discord Görevi',
    mancium_balance: 'Mancium Bakiyesi',
    mancium_balance_quest: 'Mancium Görevi',
    mancgames_quest1: 'Okey Muhabbet Görevi',
    mancgames_quest2: '101 Muhabbet Görevi',
    mancgames_quest3: 'Rummikub Social Görevi',
    reference: 'Referans Durumu',
    referer: 'Referansı',
    points: 'Puanı',
    created_at: 'Oluşturma Tarihi',
    updated_at: 'Son Güncelleme',
    account_id: 'Account ID',
    reward: 'Ödül',
    reward_status: 'Ödül Durumu',
};
class Contest extends Component {
    constructor(props){
        super(props);
        this.state = {
            people: [],
            exp: 'expanded-',
            modal: false,
            ban_modal: false,
            id: null,
            page_count: 1,
            fiftypoints: 0,
            referers: 0,
            people_count: 0,
            zero_points: 0,
            greater150: 0,
            page: 1,
            limit: 10,
            sort_type: 'DESC',
            sort_key: 'points',
            person: {},
            search_key: '',
        }
    }
    componentDidMount(){
        this.handlePage();
    }
    handlePage = () => {
        axios.post('../api/auth/contest', {
            page: this.state.page,
            limit: this.state.limit,
            sort_key: this.state.sort_key,
            sort_type: this.state.sort_type
        })
        .then(res => {
            this.setState({
                people: res.data.data,
                page_count: res.data.count,
                fiftypoints: res.data.fiftypoints,
                referers: res.data.referers,
                people_count: res.data.people_count,
                zero_points: res.data.zero_points,
                greater150: res.data.greater150,
            })
        })
    }
    handleChange = (i, e, isExpanded) => {
        if(!e.target.classList.contains('complated-btns')){
            if(isExpanded){
                this.setState({
                    exp: 'expanded-' + i
                });
            }
            else {
                this.setState({
                    exp: 'expanded-'
                });
            }
        }
    }
    
    onModal = (status, id) => {
        this.setState({
            modal: status,
            id: id,
        })
        axios.get(`../api/auth/contest-person/${id}`)
        .then(async res => {
            await Object.entries(res.data.person).forEach(([key, value]) => {
                this.setState(prevState => ({
                    person: {
                        ...prevState.person,
                        [key]: value
                    }
                }));
            })
        })
    }
    onBanModal = (status, id) => {
        this.setState({
            ban_modal: status,
            id: id,
        })
    }
    onBanSubmit = () => {
        axios.post(`../api/auth/ban-person/${this.state.id}`)
        .then(async res => {
            if(res.data.data && res.data.status){
                this.handlePage();
                this.onBanCancel();
            }
        })
    }
    onBanCancel = () => {
        this.setState({
            ban_modal: false,
            id: null,
        });
    }
    onSubmit = async (to, id) => {
        axios.post('../api/auth/contest-update/', this.state.person)
        .then(res => {
            console.log(res.data);
            if(res.data){
                this.setState({
                    person: {},
                    modal: false,
                    id: null,
                });
                this.handlePage();
            }
        });
    }
    onCancel = () => {
        this.setState({
            modal: false,
        });
    }
    handlePagination = async (event, value) => {
        await this.setState({
            page: value,
        })
        this.handlePage();
    }
    sortBy = async (value, event) => {
        await this.setState({
            sort_key: value,
            sort_type: this.state.sort_type === 'DESC' ? 'ASC' : 'DESC'
        })
        this.handlePage();
    }
    sortType = () => {
        this.setState({
            sort_type: this.state.sort_type === 'DESC' ? 'ASC' : 'DESC'
        })
    }
    handleInput = (e) => {
        this.setState(prevState => ({
            person:{
                ...prevState.person,
                [e.target.name]: e.target.value
            }
        }));
    }
    handleSearch = async (e) => {
        await this.setState({
            search_key: e.target.value,
        })
        if(this.state.search_key.length > 3){
            axios.post('../api/auth/contest', {
                search_key: this.state.search_key,
                page: this.state.page,
                limit: this.state.limit,
                sort_key: this.state.sort_key,
                sort_type: this.state.sort_type
            })
            .then(res => {
                this.setState({
                    people: res.data.data
                })
            })
        }else {
            this.handlePage();
        }
    }
    render(){
        return(
            <>
                <div className="content">
                    <Col md="12" className={(this.state.modal ? 'opened' : '' )+ " t-modal-body"}>
                        <Card className="contest-modal">
                            <CardHeader>
                                Kullanıcıyı Düzenle
                            </CardHeader>
                            <CardBody className='mt-2'>
                                <div className='modal-form-area'>
                                    {Object.entries(ui_translate).map(([key, value]) => {
                                        if(key != 'mancgames_quest')
                                        return (
                                            <>
                                                <Col key={key} className="px-3 mb-2 mt-2" md="12">
                                                    <TextField
                                                        label={value}
                                                        id="filled-size-small"
                                                        size="small"
                                                        className="mb-2"
                                                        name={key}
                                                        value={this.state.person[key] ? this.state.person[key] : ''}
                                                        onChange={this.handleInput}
                                                        fullWidth
                                                    />
                                                </Col>
                                            </>
                                        )
                                    })}
                                </div>
                                <div className='d-flex justify-content-center p-2'>
                                    <Button 
                                        className="btn-fill ml-3" 
                                        type="onclick"
                                        onClick={this.onSubmit.bind(this, this.state.to, this.state.id)}
                                    >
                                        Onayla
                                    </Button>
                                    <Button 
                                        className="btn-fill ml-3" 
                                        type="onclick"
                                        onClick={this.onCancel.bind(this, this.state.to, this.state.id)}
                                    >
                                        Vazgeç
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="12" className={(this.state.ban_modal ? 'opened' : '' )+ " t-modal-body"}>
                        <Card className="contest-modal">
                            <CardHeader>
                                Kullanıcıyı Banlamak İstediğinize Emin misiniz ?
                            </CardHeader>
                            <CardBody className='mt-2'>
                                <div className='d-flex justify-content-center p-2'>
                                    <Button 
                                        className="btn-fill ml-3" 
                                        type="onclick"
                                        onClick={this.onBanSubmit}
                                    >
                                        Onayla
                                    </Button>
                                    <Button 
                                        className="btn-fill ml-3" 
                                        type="onclick"
                                        onClick={this.onBanCancel}
                                    >
                                        Vazgeç
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h4 className="title">Contest</h4>
                                </CardHeader>
                                <CardBody className='col-6'>
                                    <Col className="user-inf">
                                        <p className="user-title">Katılım: </p>
                                        <p className="user-explain">{this.state.people_count}</p>
                                    </Col>
                                    <Col className="user-inf">
                                        <p className="user-title">150 Puan Üzeri: </p>
                                        <p className="user-explain">{this.state.greater150}</p>
                                    </Col>
                                    <Col className="user-inf">
                                        <p className="user-title">50 Puan Üzeri: </p>
                                        <p className="user-explain">{this.state.fiftypoints}</p>
                                    </Col>
                                    <Col className="user-inf">
                                        <p className="user-title">Puanı Olan: </p>
                                        <p className="user-explain">{this.state.zero_points}</p>
                                    </Col>
                                    <Col className="user-inf">
                                        <p className="user-title">Referans Sayısı: </p>
                                        <p className="user-explain">{this.state.referers}</p>
                                    </Col>
                                </CardBody>
                                <CardBody>
                                    <Table className="tablesorter apply-table" responsive>
                                        <thead className="text-primary apply-thead">
                                            <tr>
                                                <th className="width-10">
                                                    <button type='onclick' className='a-button' onClick={this.sortBy.bind(this, 'id')}>
                                                        ID
                                                        <button type='onclick' className='a-button' onClick={this.sortType}>
                                                            <i className={`fa fa-arrow-${this.state.sort_type === 'DESC' ? 'down' : 'up'} ml-2`}></i>
                                                        </button>
                                                    </button>
                                                </th>
                                                <th className="width-30 text-center">
                                                    Cüzdan Adresi
                                                    <Col className="px-3 mb-2 mt-2" md="12">
                                                        <TextField
                                                            label={'Kullanıcı Ara'}
                                                            id="filled-size-small"
                                                            size="small"
                                                            className="mb-2"
                                                            value={this.state.search_key}
                                                            onChange={this.handleSearch}
                                                            fullWidth
                                                        />
                                                    </Col>
                                                </th>
                                                <th className="width-10">
                                                    <button type='onclick' className='a-button' onClick={this.sortBy.bind(this, 'points')}>
                                                        Puan
                                                        <button type='onclick' className='a-button' onClick={this.sortType}>
                                                            <i className={`fa fa-arrow-${this.state.sort_type === 'DESC' ? 'down' : 'up'} ml-2`}></i>
                                                        </button>
                                                    </button>
                                                </th>
                                                <th className="width-10">Referans Sayısı</th>
                                                <th className="width-10">
                                                    <button type='onclick' className='a-button' onClick={this.sortBy.bind(this, 'points')}>
                                                        Rank
                                                        <button type='onclick' className='a-button' onClick={this.sortType}>
                                                            <i className={`fa fa-arrow-${this.state.sort_type === 'DESC' ? 'down' : 'up'} ml-2`}></i>
                                                        </button>
                                                    </button>
                                                </th>
                                                <th className="width-10">
                                                    <button type='onclick' className='a-button' onClick={this.sortBy.bind(this, 'created_at')}>
                                                        Account ID
                                                    </button>
                                                </th>
                                                <th className="width-10">
                                                    <button type='onclick' className='a-button' onClick={this.sortBy.bind(this, 'created_at')}>
                                                        Ödül
                                                    </button>
                                                </th>
                                                <th className="width-10">#</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.people.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="apply-td" colSpan={8}>
                                                            <Accordion 
                                                                expanded={this.state.exp === ('expanded-' + index)} 
                                                                onChange={this.handleChange.bind(this, index)
                                                            }>
                                                                <AccordionSummary
                                                                    aria-controls="panel1bh-content"
                                                                    id="panel1bh-header"
                                                                >
                                                                    <td className="width-10">{item['id']}</td>
                                                                    <td className="width-30">{item['wallet_address']}</td>
                                                                    <td className="width-10">{item['points']}</td>
                                                                    <td className="width-10">{item['referers'].length}</td>
                                                                    <td className="width-10">{item['rank']}</td>
                                                                    <td className="width-10">{item['account_id']}</td>
                                                                    <td className="width-10">{item['reward']} mancium</td>
                                                                    <td className="width-10 transactions-buttons">
                                                                        <a className="complated-btns" onClick={this.onModal.bind(this, true, item['id'])} href="#" role="button">
                                                                            <i className="complated-btns tim-icons sidebar-icons text-center icon-pencil"></i>
                                                                        </a>
                                                                        <a className="complated-btns" onClick={this.onBanModal.bind(this, true, item['id'])} href="#" role="button">
                                                                            <i className="fa fa-ban"></i>
                                                                        </a>
                                                                    </td>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Row>
                                                                        <Card>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h4">Details</CardTitle>
                                                                            </CardHeader>
                                                                            <CardBody className='col-8'>
                                                                                <Col className="user-inf">
                                                                                    <p className="user-title">Cüzdan Adresi: </p>
                                                                                    <p className="user-explain">{item['wallet_address']}</p>
                                                                                </Col>
                                                                                <Col className="user-inf">
                                                                                    <p className="user-title">Twitter Kullanıcı Adı: </p>
                                                                                    <p className="user-explain">{item['twitter_username']}</p>
                                                                                </Col>
                                                                                {item['twitter_retweets'] != null ? 
                                                                                (
                                                                                    <Col className={item['twitter_retweets']['announcement_tweet'] ? 'user-inf' : 'd-none'}>
                                                                                        <p className="user-title">Announcement Tweet RT: </p>
                                                                                        <p className="user-explain">{
                                                                                            item['twitter_retweets']['announcement_tweet'] ? (
                                                                                                item['twitter_retweets']['announcement_tweet'].map(item => {
                                                                                                    return item + ', ';
                                                                                                })
                                                                                            ) : ''}
                                                                                        </p>
                                                                                    </Col>
                                                                                )
                                                                                : ''}
                                                                                {item['twitter_retweets'] != null ? (
                                                                                    <Col className={item['twitter_retweets']['last_tweet'] ? 'user-inf' : 'd-none'}>
                                                                                        <p className="user-title">Son Tweet RT: </p>
                                                                                        <p className="user-explain">{
                                                                                            item['twitter_retweets']['last_tweet'] ? (
                                                                                                item['twitter_retweets']['last_tweet'].map(item => {
                                                                                                    return item + ', ';
                                                                                                })
                                                                                            ) : ''}
                                                                                        </p>
                                                                                    </Col>
                                                                                ) : ''}
                                                                                {item['twitter_likes'] != null ? (
                                                                                    <Col className={item['twitter_likes']['announcement_tweet'] ? 'user-inf' : 'd-none'}>
                                                                                        <p className="user-title">Announcement Tweet Beğeni: </p>
                                                                                        <p className="user-explain">{
                                                                                            item['twitter_likes']['announcement_tweet'] ? (
                                                                                                item['twitter_likes']['announcement_tweet'].map(item => {
                                                                                                    return item + ', ';
                                                                                                })
                                                                                            ) : ''}
                                                                                        </p>
                                                                                    </Col>
                                                                                ) : ''}
                                                                                
                                                                                {item['twitter_likes'] != null ? (
                                                                                    <Col className={item['twitter_likes']['last_tweet'] ? 'user-inf' : 'd-none'}>
                                                                                        <p className="user-title">Son Tweet Beğeni: </p>
                                                                                        <p className="user-explain">{
                                                                                            item['twitter_likes']['last_tweet'] ? (
                                                                                                item['twitter_likes']['last_tweet'].map(item => {
                                                                                                    return item + ', ';
                                                                                                })
                                                                                            ) : ''}
                                                                                        </p>
                                                                                    </Col>
                                                                                ) : ''}
                                                                                
                                                                                {item['twitter_follows'] != null ? (
                                                                                    <Col className={item['twitter_follows']['follow'] ? 'user-inf' : 'd-none'}>
                                                                                        <p className="user-title">Twitter Takip: </p>
                                                                                        <p className="user-explain">{
                                                                                            item['twitter_follows']['follow'] ? (
                                                                                                item['twitter_follows']['follow'].map(item => {
                                                                                                    return item + ', ';
                                                                                                })
                                                                                            ) : ''}
                                                                                        </p>
                                                                                    </Col>

                                                                                ) : ''}
                                                                                
                                                                                <Col className={(item['telegram_onumber_quest'] || item['telegram_mancium_quest']) ? 'user-inf' : 'd-none'}>
                                                                                    <p className="user-title">Telegram: </p>
                                                                                    <p className="user-explain">
                                                                                        {item['telegram_onumber_quest'] ? 'ONumbers, ' : ''}
                                                                                        {item['telegram_mancium_quest'] ? 'Mancium Global' : ''}
                                                                                    </p>
                                                                                </Col>
                                                                                <Col className={item['discord_quest'] == 1 ? 'user-inf' : 'd-none'}>
                                                                                    <p className="user-title">Discord Kullanıcı Adı: </p>
                                                                                    <p className="user-explain">{item['discord_username']}</p>
                                                                                </Col>
                                                                                <Col className={(item['mancgames_quest1'] == 1 || item['mancgames_quest1'] == 1 || item['mancgames_quest1'] == 1) ? 'user-inf' : 'd-none'}>
                                                                                    <p className="user-title">Manc Games: </p>
                                                                                    <p className="user-explain">
                                                                                        {item['mancgames_quest1'] == 1 ? 'Okey Muhabbet, ' : ''}
                                                                                        {item['mancgames_quest2'] == 1 ? '101 Muhabbet, ' : ''}
                                                                                        {item['mancgames_quest3'] == 1 ? 'Rummikub Social' : ''}
                                                                                    </p>
                                                                                </Col>
                                                                                <Col className={item['reference'] == 1 ? 'user-inf' : 'd-none'}>
                                                                                    <p className="user-title">Referansı: </p>
                                                                                    <p className="user-explain">{item['referer']}</p>
                                                                                </Col>
                                                                                <Col className="user-inf">
                                                                                    <p className="user-title">Referans Sayısı: </p>
                                                                                    <p className="user-explain">{item['referers'].length}</p>
                                                                                </Col>
                                                                                <Col className="user-inf">
                                                                                    <p className="user-title">Puan: </p>
                                                                                    <p className="user-explain">{item['points']}</p>
                                                                                </Col>
                                                                                <Col className="user-inf">
                                                                                    <p className="user-title">Kayıt Tarihi: </p>
                                                                                    <p className="user-explain">{item['date']}</p>
                                                                                </Col>
                                                                                <Col className={'user-inf'}>
                                                                                    <p className="user-title">Account ID: </p>
                                                                                    <p className="user-explain">
                                                                                        {item['account_id']}
                                                                                    </p>
                                                                                </Col>
                                                                                <Col className={'user-inf'}>
                                                                                    <p className="user-title">Kazandığı Ödül: </p>
                                                                                    <p className="user-explain">
                                                                                        {item['reward']} mancium
                                                                                    </p>
                                                                                </Col>
                                                                            </CardBody>
                                                                            <CardBody className={item['referers'].length > 0 ? 'col-9 contest-referers' : 'd-none'}>
                                                                                <CardTitle tag="h4">Referansları</CardTitle>
                                                                                {item['referers'].map(el => {
                                                                                    return (
                                                                                        <Col className="user-inf">
                                                                                            <p className="user-title mr-2">{el['wallet_address']}: </p>
                                                                                            <p className="user-explain">{el['points']}</p>
                                                                                        </Col>
                                                                                    );
                                                                                })}
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Row>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                                <Stack spacing={2} className="my-2">
                                    <Pagination count={this.state.page_count} variant="outlined" onChange={this.handlePagination} />
                                </Stack>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default Contest;