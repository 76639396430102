import TableList from "views/TableList.js";
import axios from "axios";
import Jobs from "views/Jobs";
import Messages from "views/Messages";
import Applylist from "views/Applylist";
import Transactions from "views/Transactions";
import Banner from "views/Banner";
import Swap from "views/Swaps";
import Blog from "views/Blog";
import Contest from "views/Contest";
import News from "views/News";
var routes = [
  {
    path: "/sayfalar",
    name: "Sayfalar",
    icon: "tim-icons icon-puzzle-10",
    component: TableList,
    layout: "/admin",
    form: '',
    active: 1,
    extra: 0,
  },
  {
    path: "/contest",
    name: "Contest",
    icon: "tim-icons icon-puzzle-10",
    component: Contest,
    layout: "/admin",
    form: '',
    active: 1,
  },
  {
    path: "/swap",
    name: "Swaps",
    icon: "tim-icons icon-puzzle-10",
    component: Swap,
    layout: "/admin",
    form: '',
    active: 1,
  },
  {
    path: "/news",
    name: "Manc Games News",
    icon: "tim-icons icon-puzzle-10",
    component: News,
    layout: "/admin",
    form: '',
    active: 1,
  },
  {
    path: "/blog",
    name: "Blog",
    icon: "tim-icons icon-puzzle-10",
    component: Blog,
    layout: "/admin",
    form: '',
    active: 1,
  },
  {
    path: "/Banner",
    name: "Banner",
    icon: "tim-icons icon-puzzle-10",
    component: Banner,
    layout: "/admin",
    form: '',
    active: 1,
  },
  {
    path: "/transactions",
    name: "Transactions",
    icon: "tim-icons icon-puzzle-10",
    component: Transactions,
    layout: "/admin",
    form: '',
    active: 1,
  },
  {
    path: "/messages",
    name: "Messages",
    icon: "tim-icons icon-puzzle-10",
    component: Messages,
    layout: "/admin",
    form: '',
    active: 1,
  },
  {
    path: "/basvurular",
    name: "Başvurular",
    icon: "tim-icons icon-puzzle-10",
    component: Applylist,
    layout: "/admin",
    form: '',
    active: 0,
  },
  {
    path: "/ilanlar",
    name: "İlanlar",
    icon: "tim-icons icon-puzzle-10",
    component: Jobs,
    layout: "/admin",
    form: '',
    active: 1,
  }
];

axios.get('../api/auth/pages-details')
.then(async res => {
  res.data.values.map((item) => {
    if(item.path != '/Banner')
    routes.push(item);
  })
});

export default routes;
