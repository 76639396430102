import React from 'react';
import {
    Button,
    Col,
    Row,
  } from "reactstrap";
import TextField from '@mui/material/TextField';
import axios from 'axios';

/* Questions */
const ActionReleatedNews = props => {
    if(props.success){
        props.ActionItemsList.forEach((item, index) => {
            props.deleteActionItemFromState(index);
        })
    }
    const deleteActionItemFromState = index => () => {
        props.deleteActionItemFromState(index);
    };

    return (
            <Row className="mt-2">
                {props.ActionItemsList.map((item, index) => (
                    <Col md="12" className="d-flex mt-1" key={index + 1}>
                        <span className='action-list-marker d-flex'>
                            <a
                                className='mr-2'
                                style={{cursor:'pointer', fontSize:22, color:'red'}}
                                onClick={deleteActionItemFromState(index)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </a>
                            {index + 1}.
                        </span>
                        <Col md="11" className="d-flex pl-0 align-items-center">
                            <Col md="12"><a style={{paddingright: '8px', fontWeight: '700'}}>Link: </a>{item.rnews_link}</Col>
                        </Col>
                    </Col>
                ))}
            </Row>
    );
}

class ReleatedNews extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            rnews_link: "",
            rnews_image: null,
            rnews_image_name: '',
            ActionItemsList: [],
        };
    }

    addActionItemToState = async (rnews_link, rnews_image) => {
        let toBeAddedActionItem = {
            rnews_link: rnews_link,
            rnews_image: rnews_image,
        };
        await this.setState(prevState => ({
            ActionItemsList: prevState.ActionItemsList.concat(toBeAddedActionItem)
        }));
        this.props.list(this.state.ActionItemsList);
    };

    handleChange = event => {
        event.persist();
        this.setState(prevState => ({
            rnews_link: event.target.name === "rnews_link" ? event.target.value : prevState.rnews_link,
        }));
    };
    
    handleSubmission = async (event) => {
        event.preventDefault();
        let data = new FormData;
        data.append('image', this.state.rnews_image);
        await axios.post('../api/auth/image-upload-rn', data)
        .then(res => {
            if(res.data.status){
                this.setState({
                    rnews_image_name: res.data.image,
                })
            }
        })
        await this.addActionItemToState(this.state.rnews_link, this.state.rnews_image_name);
        this.setState({
            rnews_link: "",
            rnews_image: null,
            rnews_image_name: "",
        });
    };

    deleteActionItemFromState = index => {
        const ActionItemsList = [...this.state.ActionItemsList];
        ActionItemsList.splice(index, 1);
        this.setState({ ActionItemsList });
    };

    handleFileChange =(e) => {
        this.setState({
            rnews_image: e.target.files[0]
        })
    }
    render() {
        return(
            <>
                <Col md="12" className='addable-from'>
                    <h4 className='ml-3'>Releated News</h4>
                    <Col md="12" className="d-flex">
                        <TextField
                            className="mt-1 pr-1"
                            label="Link"
                            id="rnews_link"
                            name="rnews_link"
                            size="small"
                            onChange={this.handleChange}
                            value={this.state.rnews_link}
                            fullWidth
                        />
                        <TextField
                            size="small"
                            className="mt-1"
                            id="rnews_link"
                            name="rnews_image"
                            type="file"
                            onChange={this.handleFileChange}
                            fullWidth
                        /> 
                        <Button className="btn-fill ml-2" type="onclick" onClick={this.handleSubmission} style={{overflow:'auto !important'}}>
                            +
                        </Button>
                    </Col>
                    <Col md="12">
                        <ActionReleatedNews
                            ActionItemsList={this.state.ActionItemsList}
                            deleteActionItemFromState={this.deleteActionItemFromState}
                            success={this.props.success}
                        />
                    </Col>
                </Col>
            </>
        );
    }
}

export default ReleatedNews;