import React, { Component, useState } from "react";
import axios from 'axios';
import {
    Card,
    CardHeader,
    CardBody,
    Table,
    Row,
    Col,
} from "reactstrap";
import Bannerform from "components/Form/Bannerform";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import DnDList from 'react-dnd-list';

const AccordionItem = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return (
        <Accordion
            key={props.index}
            expanded={expanded === 'panel1'+ props.index} 
            onChange={handleChange('panel1' + props.index)}
        >
            <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                expandIcon={<a className="detail-buttons"><i className="tim-icons icon-pencil mx-1" /></a>}
            >
                <td className="mw-35">{<img className="img-fluid" src={'https://mancgames.com/storage/img/' + props.item['desc_tr']}/> }</td>
                <td className="mw-35">{<img className="img-fluid" src={'https://mancgames.com/storage/img/' + props.item['desc_en']}/> }</td>
                <td className="width-15">{props.item['title_tr']}</td>
                <td className="mw-15 messages-buttons">
                    
                </td>
            </AccordionSummary>
            <AccordionDetails>
                <Bannerform
                    values={props.item}
                    action={'update'}
                    // handleSuccess={
                    //     (values) => {
                    //         this.setState({
                    //             values: values,
                    //             exp: 'expanded-',
                    //         })
                    //     }
                    // }
                />
            </AccordionDetails>
        </Accordion>
    )
}

const MyList = (props) => {
    const items = [];
    axios.get('../api/auth/Banner')
    .then(res => {
        let values = res.data.values
        values.forEach((item, index) => {
            items.push(
                <AccordionItem
                    item={item}
                    index={index}
                />
            )
        });
    })
    
    const [list, setList] = useState(items);
    return (
      <ul className="w-100 drag-list">
        <DnDList
          items={list}
          itemComponent={props.itemComponent}
          setList={setList}
        />
      </ul>
    )
  }
  
class Banner extends Component {
    constructor(props){
        super(props);
        this.state = {
            text_tr: '',
            text_en: '',
            img_m_tr: '',
            img_d_tr: '',
            img_m_tr_Pre: null,
            img_d_tr_Pre: null,
            message: [],
            status: null,
            values: [],
            exp: 'expanded-',
        }
    }
    componentDidMount(){
        this.handleGet();
    }
    handleGet = e => {
        axios.get('../api/auth/Banner')
        .then(res => {
            this.setState({
                values: res.data.values
            })
        })
    }
    Item = props => {
        this.state.values[props.index] = props;
        const dnd = props.dnd;
        console.log(props.itemInDrag)
        if(props.itemInDrag){
          if(!(props.listInDrag)){
            let newlines = [];
            // this.state.values.map(element => {
            //   newlines.push([element.item.key, element.index])
            // })
            // let data = new FormData(),
            //     href = '../api/auth/update-line';
            // data.append('newlines', JSON.stringify(newlines));
            // axios.post(href, data);
          }
        }
        return (
          <li
            style={{ ...dnd.item.styles, ...dnd.handler.styles }}
            className={dnd.item.classes}
            ref={dnd.item.ref}
            {...dnd.handler.listeners}
            value={this.state.values[props]} 
          >
            {props.item}
          </li>
        )
      }
      
    render(){
        return(
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Bannerform
                                        values={this.state}
                                        action={'create'}
                                        handleSuccess={
                                            (values) => {
                                                this.setState({
                                                    values: values,
                                                    exp: 'expanded-',
                                                })
                                            }
                                        }
                                    />
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter apply-table" responsive>
                                        <thead className="text-primary apply-thead">
                                            <tr>
                                                <th className="mw-35 p-3">Masaüstü Site</th>
                                                <th className="mw-35 p-3">Mobil Site</th>
                                                <th className="width-15 p-3">Metin</th>
                                                <th className="mw-15 p-3 text-center">#</th>
                                            </tr>
                                        </thead>
                                    </Table>
                                    <MyList
                                        itemComponent={this.Item}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default Banner;