import React from "react";

// reactstrap components
import { Dropdown, DropdownToggle, Badge } from "reactstrap";
import { ThemeContext, themes } from "contexts/ThemeContext";
import { backgroundColors } from "contexts/BackgroundColorContext";
import axios from 'axios';

function FixedPlugin(props) {
  const [dropDownIsOpen, setdropDownIsOpen] = React.useState(false);
  const handleClick = () => {
    setdropDownIsOpen(!dropDownIsOpen);
  };
  var theColor = '';
  const setThema = (v) => {
    let data = new FormData;
    data.append('thema', v);
    axios.post('../api/auth/thema', data);
  }
  const setColor = async (v) => {
    let data = new FormData;
    data.append('color', v);
    await axios.post('../api/auth/color', data)
    .then(res => {
      if(res.data.color == 0){
        theColor = 'primary';
      }
      if(res.data.color == 1){
        theColor = 'blue';
      }
      if(res.data.color == 2){
        theColor = 'green';
      }
    });
  }
  return (
    <div className="fixed-plugin">
      <Dropdown isOpen={dropDownIsOpen} toggle={handleClick}>
        <DropdownToggle tag="div">
          <i className="fa fa-cog fa-2x" />
        </DropdownToggle>
        <ul className="dropdown-menu show">
          <li className="header-title">TEMA RENGİ</li>
          <li className="adjustments-line">
            <div className="badge-colors text-center">
              <Badge
                color="primary"
                className={
                  props.bgColor === theColor ? "active" : ""
                }
                onClick={() => {
                  props.handleBgClick(backgroundColors.primary);
                  setColor(0);
                }}
              />{" "}
              <Badge
                color="info"
                className={
                  props.bgColor === theColor ? "active" : ""
                }
                onClick={() => {
                  props.handleBgClick(backgroundColors.blue);
                  setColor(1);
                }}
              />{" "}
              <Badge
                color="success"
                className={
                  props.bgColor === theColor ? "active" : ""
                }
                onClick={() => {
                  props.handleBgClick(backgroundColors.green);
                  setColor(2);
                }}
              />{" "}
            </div>
          </li>
          <li className="adjustments-line text-center color-change">
            <ThemeContext.Consumer>
              {({ changeTheme }) => (
                <>
                  <span className="color-label">AYDINLIK</span>{" "}
                  <Badge
                    className="light-badge mr-2"
                    onClick={() => {changeTheme(themes.light); setThema(0)}}
                  />{" "}
                  <Badge
                    className="dark-badge ml-2"
                    onClick={() => {changeTheme(themes.dark); setThema(1)}}
                  />{" "}
                  <span className="color-label">KARANLIK</span>{" "}
                </>
              )}
            </ThemeContext.Consumer>
          </li>
        </ul>
      </Dropdown>
    </div>
  );
}

export default FixedPlugin;
