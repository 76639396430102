import React, { Component } from "react";
import axios from 'axios';
import Newsform from 'components/Form/Newsform';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import {
  Card,
  Table,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { dateConvert } from 'helpers';

class News extends Component {
  constructor(props) {
    super(props);
    
    this.state = { 
      values: [],
    };
  }
  componentDidMount(){
    axios.get('../api/auth/news')
    .then(res => {
      if(res.data.status == false){
        this.setState({
          values: []
        });
      }
      else{
        this.setState({
          values: res.data.values
        });
      }
    });
  }
  render(){
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="title">Manc Games News</h4>
                </CardHeader>
                <CardBody>
                  <Newsform
                    update={false}
                    handleResponse={ (e) => {
                      this.setState({
                        values: e
                      })
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary detail-table">
                    <tr className="p-2 d-flex">
                        <th className={"mw-30"}>Resim</th>
                        <th className={"mw-30"}>Başlık 🇹🇷</th>
                        <th className={"mw-30"}>Tarih</th>
                        <th className={"mw-10"}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.values.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td colSpan={6}>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<a className="detail-buttons"><i className="tim-icons icon-pencil mx-1" /></a>}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                              <table className="w-100">
                                <tbody>
                                  <tr>
                                    <td className={"mw-30"}>
                                      <img className="img-fluid" src={`https://mancgames.com/storage/img/${item.image1}`}/> 
                                    </td>
                                    <td className={"embed-td mw-30"}>
                                      {item.title_tr}
                                    </td>
                                    <td className={"embed-td mw-30"}>
                                      {dateConvert(item.created_at)}
                                    </td>
                                    <td className={"embed-td mw-10"}></td>
                                  </tr>
                                </tbody>
                              </table>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Newsform
                                  id={item.id}
                                  update={true}
                                  handleResponse={ (e) => {
                                    this.setState({
                                      values: e
                                    })
                                  }}
                                />
                              </AccordionDetails>
                            </Accordion>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  
}
export default News;
