import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Col,
  } from "reactstrap";
  import axios from 'axios';
class Applicant extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: [],
            forms: [],
        }
        axios.get('../api/auth/applicant/'+ (this.props.userid !== undefined ? this.props.userid : 1))
        .then(async res => {
            await this.setState({
               user: res.data.user,
               forms: res.data.forms
            });
        });
    }

    render(){
        return (
            <>
                <Col lg="6" md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Başvuran Bilgileri</CardTitle>
                        </CardHeader>
                        <CardBody>
                            {
                                Object.keys(this.state.user).map((item, index) => {
                                    return (
                                        <Col className="user-inf" key={index}>
                                            <p className="user-title">{item}: </p>
                                            <p className="user-explain">{this.state.user[item]}</p>
                                        </Col>
                                    );
                                })
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="6" md="12">
                    <Card className="answers-card">
                        <CardHeader>
                            <CardTitle tag="h4">Yanıtlar</CardTitle>
                        </CardHeader>
                        <CardBody>
                            {this.state.forms.map((item, index)=>{
                                return( <Col md="12" className="answers-body" key={index}>
                                    <p className="apply-question">{item[0]}</p>
                                    <p className="apply-answer">{item[1]}</p>
                                </Col>)
                            })}
                        </CardBody>
                    </Card>
                </Col>
              
            </>
          );
    }
  
}
export default Applicant;