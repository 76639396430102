
const Iconsarray = [
    {
        icon: "icon-alert-circle-exc"
    },
    {
        icon: "icon-align-center"
    },
    {
        icon: "icon-align-left-2"
    },
    {
        icon: "icon-app"
    },
    {
        icon: "icon-atom"
    },
    {
        icon: "icon-attach-87"
    },
    {
        icon: "icon-badge"
    },
    {
        icon: "icon-bag-16"
    },
    {
        icon: "icon-bank"
    },
    {
        icon: "icon-basket-simple"
    },
    {
        icon: "icon-bell-55"
    },
    {
        icon: "icon-bold"
    },
    {
        icon: "icon-book-bookmark"
    },
    {
        icon: "icon-double-right"
    },
    {
        icon: "icon-bulb-63"
    },
    {
        icon: "icon-bullet-list-67"
    },
    {
        icon: "icon-bus-front-12"
    },
    {
        icon: "icon-button-power"
    },
    {
        icon: "icon-camera-18"
    },
    {
        icon: "icon-calendar-60"
    },
    {
        icon: "icon-caps-small"
    },
    {
        icon: "icon-cart"
    },
    {
        icon: "icon-chart-bar-32"
    },
    {
        icon: "icon-chart-pie-36"
    },
    {
        icon: "icon-chat-33"
    },
    {
        icon: "icon-check-2"
    },
    {
        icon: "icon-cloud-download-93"
    },
    {
        icon: "icon-cloud-upload-94"
    },
    {
        icon: "icon-coins"
    },
    {
        icon: "icon-compass-05"
    },
    {
        icon: "icon-controller"
    },
    {
        icon: "icon-credit-card"
    },
    {
        icon: "icon-delivery-fast"
    },
    {
        icon: "icon-email-85"
    },
    {
        icon: "icon-gift-2"
    },
    {
        icon: "icon-globe-2"
    },
    {
        icon: "icon-headphones"
    },
    {
        icon: "icon-heart-2"
    },
    {
        icon: "icon-html5"
    },
    {
        icon: "icon-double-left"
    },
    {
        icon: "icon-image-02"
    },
    {
        icon: "icon-istanbul"
    },
    {
        icon: "icon-key-25"
    },
    {
        icon: "icon-laptop"
    },
    {
        icon: "icon-light-3"
    },
    {
        icon: "icon-link-72"
    },
    {
        icon: "icon-lock-circle"
    },
    {
        icon: "icon-map-big"
    },
    {
        icon: "icon-minimal-down"
    },
    {
        icon: "icon-minimal-left"
    },
    {
        icon: "icon-minimal-right"
    },
    {
        icon: "icon-minimal-up"
    },
    {
        icon: "icon-mobile"
    },
    {
        icon: "icon-molecule-40"
    },
    {
        icon: "icon-money-coins"
    },
    {
        icon: "icon-notes"
    },
    {
        icon: "icon-palette"
    },
    {
        icon: "icon-paper"
    },
    {
        icon: "icon-pin"
    },
    {
        icon: "icon-planet"
    },
    {
        icon: "icon-puzzle-10"
    },
    {
        icon: "icon-pencil"
    },
    {
        icon: "icon-satisfied"
    },
    {
        icon: "icon-scissors"
    },
    {
        icon: "icon-send"
    },
    {
        icon: "icon-settings-gear-63"
    },
    {
        icon: "icon-settings"
    },
    {
        icon: "icon-wifi"
    },
    {
        icon: "icon-single-02"
    },
    {
        icon: "icon-single-copy-04"
    },
    {
        icon: "icon-sound-wave"
    },
    {
        icon: "icon-spaceship"
    },
    {
        icon: "icon-square-pin"
    },
    {
        icon: "icon-support-17"
    },
    {
        icon: "icon-tablet-2"
    },
    {
        icon: "icon-tag"
    },
    {
        icon: "icon-tap-02"
    },
    {
        icon: "icon-tie-bow"
    },
    {
        icon: "icon-time-alarm"
    },
    {
        icon: "icon-trash-simple"
    },
    {
        icon: "icon-trophy"
    },
    {
        icon: "icon-tv-2"
    },
    {
        icon: "icon-upload"
    },
    {
        icon: "icon-user-run"
    },
    {
        icon: "icon-vector"
    },
    {
        icon: "icon-video-66"
    },
    {
        icon: "icon-wallet-43"
    },
    {
        icon: "icon-volume-98"
    },
    {
        icon: "icon-watch-time"
    },
    {
        icon: "icon-world"
    },
    {
        icon: "icon-zoom-split"
    },
    {
        icon: "icon-refresh-01"
    },
    {
        icon: "icon-refresh-02"
    },
    {
        icon: "icon-shape-star"
    },
    {
        icon: "icon-components"
    },
    {
        icon: "icon-triangle-right-17"
    },
    {
        icon: "icon-button-pause"
    },
    {
        icon: "icon-simple-remove"
    },
    {
        icon: "icon-simple-add"
    },
    {
        icon: "icon-simple-delete"
    }
];

export default Iconsarray;