import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Table,
    Row,
    Col,
  } from "reactstrap";
  import axios from 'axios';
  import Userdetails from './User-details';
  import Dropdown from 'react-bootstrap/Dropdown';

class Applies extends Component {
    constructor(props){
        super(props);
        this.state = {
            applies: [],
            alljobs: [],
        }
        axios.get('../api/auth/applies/' + this.props.jobid)
        .then(res => {
            this.setState({
                applies: res.data.applies,
                alljobs: res.data.alljobs
            });
        });
    }
    handleSelect = async (i, e) => {
        await this.setState({
            jobid: i,
        });
        axios.get('../api/auth/applies/' + this.props.jobid)
        .then(res => {
            this.setState({
                applies: res.data.applies,
            })
        })
    }
    render(){
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic" className="btn-fill" >
                                            Meslekler
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                        {this.state.alljobs.map(item => {
                                            return (
                                                <Dropdown.Item 
                                                    onClick={this.handleSelect.bind(this, item.category)} 
                                                    id={item.icon}
                                                >
                                                {item.name_tr}
                                                </Dropdown.Item>)
                                        })} 
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter apply-table" responsive>
                                        <thead className="text-primary apply-thead">
                                            <tr>
                                                <th className="mw-30">İsim Soyisim</th>
                                                <th className="mw-30">Başvurduğu Pozisyon</th>
                                                <th className="mw-30">Başvuru Tarihi</th>
                                                <th className="mw-6">#</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.applies).map((item, index) => {
                                                return (
                                                    <Userdetails
                                                        item={item}
                                                        index={index}
                                                    />
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
              
            </>
          );
    }
  
}
export default Applies;