import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import AdminLayout from "layouts/Admin/Admin.js";
import Login from "../auth/Login";


export default class PrivateRoute extends Component {


    render() {
        const {component: Component, ...rest} = this.props;
        const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
        return (
            <>
                {
                    isAuthenticated ? 
                    (
                        <Route exact path="/" {...rest} 
                            render={
                                (props) => <AdminLayout 
                                                {...props}
                                                handleClick={(prop)=>{
                                                    this.props.handleClick(prop);
                                                }}
                                            />} />
                    ) 
                    : 
                    (
                        <Route component={Login}/>
                    )
                }
            </>
        );
    }
}
