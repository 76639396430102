import { createContext } from "react";
import axios from "axios";
export const backgroundColors = {
  primary: "primary",
  blue: "blue",
  green: "green",
};
var theColor = '';
axios.get('../api/auth/user')
.then(res => {
  if(res.data.status){
    if(res.data.user.color === '0'){
      theColor = 'primary';
    }
    if(res.data.user.color === '1'){
      theColor = 'blue';
    }
    if(res.data.user.color === '2'){
      theColor = 'green';
    }
  }
});
export const BackgroundColorContext = createContext({
  color: backgroundColors[theColor],
  changeColor: (color) => {},
});
