import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import {
  Button,
  Row,
  Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
	
class Applyform extends Component {
    constructor(props){
        super(props);

        this.state = {
            name_tr: '',
            name_en: '',
            desc_tr: '',
            desc_en: '',
            banner: '',
            mainjob: [],
            type: '',
            mainjobs: []
        }
        axios.get('../api/auth/job-adv/' + this.props.category)
        .then(res => {
            this.setState({
                mainjob: res.data.mainjob
            })
        })
    }
    onValueChange = (e) => {
        e.persist();
        this.setState(prevState => ({
            name_tr: e.target.name === "name_tr" ? e.target.value : prevState.name_tr,
            name_en: e.target.name === "name_en" ? e.target.value : prevState.name_en,
            desc_tr: e.target.name === "desc_tr" ? e.target.value : prevState.desc_tr,
            desc_en: e.target.name === "desc_en" ? e.target.value : prevState.desc_en,
        }));
    }
    onFileChange = (e) => {
        this.setState({
            banner: e.target.files[0]
        });
    }
    onClick = async () => {
        var formData = new FormData();

        formData.append("name_tr", this.state.name_tr);
        formData.append("name_en", this.state.name_en);
        formData.append("desc_tr", this.state.desc_tr);
        formData.append("desc_en", this.state.desc_en);
        formData.append("banner", this.state.banner);

        await axios.post('../api/auth/job-update/' + this.props.category, formData)
        .then(async res => {
            if(res.data.status == true){
                this.handleNotificate('success', res.data.msg);
                await this.setState({
                    name_tr: '',
                    name_en: '',
                    desc_tr: '',
                    desc_en: '',
                    banner: '',
                    mainjob: res.data.mainjob,
                    mainjobs: res.data.mainjobs
                });
                this.props.handleJobs({jobs: this.state.mainjobs, mainjob: this.state.mainjob});
            }
            else{
                this.handleNotificate('danger', res.data.msg);
            }
        })
        
    }
    handleNotificate = (s, msg) => {
        msg.forEach(item => {
          this.refs.notify.notificationAlert({
            place: 'br',
            message: item,
            type: s,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 5
          });
        });
    }
    render(){
        return(
            <>
                <NotificationAlert ref="notify" zIndex={9999}/>
                <Row>
                    <Col 
                        md="9"
                        className="mt-auto mb-auto"
                    >
                        <Col className="px-3 mb-2 mt-2" md="12">
                            <TextField
                                label={'Başlık 🇹🇷'}
                                id="filled-size-small"
                                name="name_tr"
                                size="small"
                                className="mb-2"
                                value={this.state.name_tr}
                                onChange={this.onValueChange}
                                placeholder={this.props.mainjob.name_tr}
                                fullWidth
                            />
                        </Col>
                        <Col className="px-3 mb-2 mt-2" md="12">
                            <TextField
                                label={'Başlık 🇺🇸'}
                                id="filled-size-small"
                                name="name_en"
                                size="small"
                                className="mb-2"
                                value={this.state.name_en}
                                onChange={this.onValueChange}
                                placeholder={this.props.mainjob.name_en}
                                fullWidth
                            />
                        </Col>
                        <Col className="px-3 mb-2 mt-2" md="12">
                            <TextField
                                label={'Açıklama 🇹🇷'}
                                id="filled-size-small"
                                name="desc_tr"
                                size="small"
                                className="mb-2"
                                value={this.state.desc_tr}
                                onChange={this.onValueChange}
                                placeholder={this.props.mainjob.desc_tr}
                                fullWidth
                            />
                        </Col>
                        <Col className="px-3 mb-2 mt-2" md="12">
                            <TextField
                                label={'Açıklama 🇺🇸'}
                                id="filled-size-small"
                                name="desc_en"
                                size="small"
                                className="mb-2"
                                value={this.state.desc_en}
                                onChange={this.onValueChange}
                                placeholder={this.props.mainjob.desc_en}
                                fullWidth
                            />
                        </Col>
                    </Col>
                    <Col className="px-3 mb-2" md="3">
                        <Form.Group className="mb-3">
                            <Form.Label className="file-input-label">Fotoğraf seçmek için tıklayın</Form.Label>
                            <Image 
                                src={this.props.mainjob.banner != null ?  ("https://mancgames.com/storage/img/" + this.props.mainjob.banner) : "https://tema.digital/wp-content/uploads/2017/07/800x600.png"}
                                className="file-input-example"
                                fluid 
                            />
                            <Form.Control 
                                type="file"
                                onChange={this.onFileChange}
                                name="file"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Button 
                    className="btn-fill ml-3" 
                    type="submit"
                    onClick={this.onClick}
                >
                    Gönder
                </Button>
            </>
        )
    }
}
export default Applyform;