import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import {
  Button,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import VideoPlayer from 'react-simple-video-player';

class Send extends Component {
  constructor(props) {
    super(props);
    this.state = 
    {
      value1: '',
      value2: '',
      value3: '',
      value4: '',
      value5: '',
      value6: '',
      message: [],
      type: '',
      status: null,
      db0: '',
      db1: '',
      db2: '',
      db3: '',
      db4: '',
      db5: '',
      db6: '',
      file: null,
      edit: this.props.edit,
      values: [],
    };
  }
  componentDidMount(){
    this.setState({
      values: this.props.values,
      db0: this.props.dbValues[0],
      db1: this.props.dbValues[1],
      db2: this.props.dbValues[2],
      db3: this.props.dbValues[3],
      db4: this.props.dbValues[4],
      db5: this.props.dbValues[5],
      db6: this.props.dbValues[6],
    });
  }
  onValue1Change = e => {
    this.setState({
      value1: e.target.value
    });
  };
  onValue2Change = e => {
    this.setState({
      value2: e.target.value
    });
  };
  onValue3Change = e => {
    this.setState({
      value3: e.target.value
    });
  };
  onValue4Change = e => {
    this.setState({
      value4: e.target.value
    });
  };
  onValue5Change = e => {
    this.setState({
      value5: e.target.value
    });
  };
  onValue6Change = e => {
    this.setState({
      value6: e.target.value
    });
  };
  onFileChange = e => {
    this.setState({
      file: e.target.files[0]
    });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    var formData = new FormData(); 

    if(this.state.file != null){
      formData.append(this.state.db0, this.state.file);
    }
    if(this.props.dbValues.length >= 2){
      formData.append(this.state.db1, this.state.value1);
    }
    if(this.props.dbValues.length >= 3){
      formData.append(this.state.db2, this.state.value2);
    }
    if(this.props.dbValues.length >= 4){
      formData.append(this.state.db3, this.state.value3);
    }
    if(this.props.dbValues.length >= 5){
      formData.append(this.state.db4, this.state.value4);
    }
    if(this.props.dbValues.length >= 6){
      formData.append(this.state.db5, this.state.value5);
    }
    if(this.props.dbValues.length >= 7){
      formData.append(this.state.db6, this.state.value6);
    }
    if(this.props.edit === true) {
      await axios.post(this.props.updateHref, formData)
      .then(res => {
        if(res.data.status == true){
          this.setState({
            type: 'success'
          })
        }
        else{
          this.setState({
            type: 'danger'
          })
        }
        this.setState({
          value1: '',
          value2: '',
          value3: '',
          value4: '',
          value5: '',
          value6: '',
          values: this.props.values,
          status: res.data.status,
          message: res.data.msg,
        });
        
      });
    }
    else {
      await axios.post(this.props.formHref, formData)
      .then(res => {
        if(res.data.status == true){
          this.setState({
            type: 'success'
          })
        }
        else{
          this.setState({
            type: 'danger'
          })
        }
        this.setState({
          value1: '',
          value2: '',
          value3: '',
          value4: '',
          value5: '',
          value6: '',
          status: res.data.status,
          message: res.data.msg,
          values: this.props.values
        });
      });
    }
  }
  onClick = async(e) => {
    await this.handleSubmit(e);
    this.props.handleClick({
      message: this.state.message,
      type: this.state.type,
    });
  }

  render(){
    return (
      <>
        <CardBody>
          <Row>
            <Col 
              md="9"
              className="mt-auto mb-auto"
            >
                <Col className="px-3 mb-2 mt-2" md="12">
                  <TextField
                    label={this.props.headers[1]}
                    id="filled-size-small"
                    size="small"
                    className="mb-2"
                    value={this.state.value1}
                    onChange={this.onValue1Change}
                    placeholder={this.props.edit === true && this.props.values.length > 0 ? this.props.values[this.props.index][this.state.db1] : '' }
                    fullWidth
                  />
                </Col>

                {this.props.dbValues.length >= 3 ? (
                  <>
                    <Col className="px-3 mb-2 mt-2" md="12">
                      <TextField
                        label={this.props.headers[2]}
                        id="filled-size-small"
                        size="small"
                        className="mb-2"
                        value={this.state.value2}
                        onChange={this.onValue2Change}
                        placeholder={this.props.edit === true && this.props.values.length > 0 ? this.props.values[this.props.index][this.state.db2] : '' }
                        fullWidth
                      />
                    </Col>
                  </>
                ) : ''}

                {this.props.dbValues.length >= 4 ? (
                  <>
                    <Col className="px-3 mb-2 mt-2" md="12">
                      <TextField
                        label={this.props.headers[3]}
                        id="filled-size-small"
                        size="small"
                        className="mb-2"
                        value={this.state.value3}
                        onChange={this.onValue3Change}
                        placeholder={this.props.edit === true && this.props.values.length > 0 ? (this.props.values[this.props.index][this.state.db3]) : '' }
                        fullWidth
                      />
                    </Col>
                  </>
                ) : ''}

                {this.props.dbValues.length >= 5 ? (
                  <>
                    <Col className="px-3 mb-2 mt-2" md="12">
                      <TextField
                        label={this.props.headers[4]}
                        id="filled-size-small"
                        size="small"
                        className="mb-2"
                        value={this.state.value4}
                        onChange={this.onValue4Change}
                        placeholder={this.props.edit === true && this.props.values.length > 0 ? this.props.values[this.props.index][this.state.db4] : '' }
                        fullWidth
                      />
                    </Col>
                  </>
                ) : ''}
                
                {this.props.dbValues.length >= 6 ? (
                  <>
                  <Col className="px-3 mb-2 mt-2" md="12">
                    <TextField
                      label={this.props.headers[5]}
                      id="filled-size-small"
                      size="small"
                      className="mb-2"
                      value={this.state.value5}
                      onChange={this.onValue5Change}
                      placeholder={this.props.edit === true && this.props.values.length > 0 ? this.props.values[this.props.index][this.state.db5] : '' }
                      fullWidth
                    />
                  </Col>
                  </>
                ) : ''}
                
                {this.props.dbValues.length >= 7 ? (
                  <>
                  <Col className="px-3 mb-2 mt-2" md="12">
                    <TextField
                      label={this.props.headers[6]}
                      id="filled-size-small"
                      size="small"
                      className="mb-2"
                      value={this.state.value6}
                      onChange={this.onValue6Change}
                      placeholder={this.props.edit === true && this.props.values.length > 0 ? this.props.values[this.props.index][this.state.db6] : '' }
                      fullWidth
                    />
                  </Col>
                  </>
                ) : ''}
                
            </Col>
            <Col className="px-3 mb-2" md="3">
              {this.props.imgValue === 'image' ? 
              (<Form.Group className="mb-3">
                <Form.Label className="file-input-label">Fotoğraf seçmek için tıklayın</Form.Label>
                <Image 
                  src={this.props.edit === true && this.props.values.length > 0 ?  "https://mancgames.com/storage/img/" + this.props.values[this.props.index][this.state.db0] 
                  : 
                  ("https://tema.digital/wp-content/uploads/2017/07/800x600.png") }
                  className="file-input-example"
                  fluid />
                <Form.Control 
                  type="file"
                  onChange={this.onFileChange}
                  name="file"
                />
              </Form.Group>) : 
              (this.props.imgValue === 'background' && this.props.edit === false ? 
              (<Form.Group className="mb-3">
                <Form.Label className="file-input-label">Fotoğraf seçmek için tıklayın</Form.Label>
                  <VideoPlayer
                    url={this.props.background ? "https://mancgames.com/storage/img/" + this.props.background : '' }
                    width={'100%'}
                    height={'auto'}
                    autoplay
                  />
                  <Form.Control 
                    type="file"
                    onChange={this.onFileChange}
                    name="file"
                  />
                </Form.Group>) : '')}
            </Col>
          </Row>
          <Button 
            className="btn-fill ml-3" 
            type="submit"
            onClick={this.onClick}
          >
            Gönder
          </Button>
        </CardBody>
      </>
    );
  }
  
}
export default Send;
