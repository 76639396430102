import React, { useState, useEffect } from "react";
import { ThemeContext, themes } from "contexts/ThemeContext";
import axios from 'axios';

export default function ThemeContextWrapper(props) {
  let bright = 0;

  const [theme, setTheme] = useState(bright === 0 ? themes.light :  themes.dark);
  function changeTheme(theme) {
    setTheme(theme);
  }
  axios.get('../api/auth/user')
  .then(res => {
    if(res.data.status){
      setTheme(res.data.user.thema === 0 ? 'white-content' : '');
    }
  })
  
  useEffect(() => {
    switch (theme) {
      case themes.light:
        default:
        document.body.classList.add("white-content");
        break;
      case themes.dark:
        document.body.classList.remove("white-content");
        break;
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
