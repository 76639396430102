import React, { Component } from 'react';
import {
    CardHeader,
    CardBody,
    Table,
    Nav,
} from "reactstrap";
import axios from 'axios';
import Applyform from 'components/Jobs/Apply-form';
import Form from 'react-bootstrap/Form';
import Newadv from './Newadv';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-router-dom';
import Jobdetails from 'components/Jobs/Job-details';
class Jobadv extends Component {
    constructor(props){
        super(props);
        this.state = {
            exp: '',
            jobs: [],
            alljobs: [],
            category: 1,
            applies: [],
            added: false,
            mainjob: '',
        }
        axios.get('../api/auth/job-adv/' + this.state.category)
        .then(res => {
            this.setState({
                jobs: res.data.jobs,
                alljobs: res.data.alljobs,
                applies: res.data.applies,
                mainjob: res.data.mainjob
            })
        });
    }
    handleSelect = async (i, e) => {
        await this.setState({
            category: i,
        });
        axios.get('../api/auth/job-adv/' + this.state.category)
        .then(res => {
            this.setState({
                jobs: res.data.jobs,
                applies: res.data.applies,
                mainjob: res.data.mainjob
            });
        })
    }
    onCheck = (i, e) => {
        axios.post('../api/auth/applies-deactive/' + i)
    }
    handleAdd = (i, e) => {
        setTimeout(() => {
            axios.get('../api/auth/job-adv/' + i)
            .then(res => {
                this.setState({
                    applies: res.data.applies,
                })
            })
        }, 500);
    }
    render(){
        return (
            <>
                <CardHeader>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" className="btn-fill" >
                            { this.state.mainjob['name_tr'] }
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        {this.state.alljobs.map((item, index) => {
                            return (
                                <Dropdown.Item 
                                    key={index}
                                    onClick={this.handleSelect.bind(this, item.id)} 
                                    id={item.icon}
                                >
                                    {item.name_tr}
                                </Dropdown.Item>
                            )
                        })}
                        </Dropdown.Menu>
                    </Dropdown>
                </CardHeader>
                <CardBody>
                    <Applyform
                        category={this.state.category}
                        handleJobs={async (prop) => {
                            await this.setState({
                                alljobs: prop.jobs,
                                mainjob: prop.mainjob
                            })
                        }}
                        mainjob={this.state.mainjob}
                    />
                    <Jobdetails
                        category={this.state.category}
                        mainjob={this.state.mainjob}
                    />
                    <Newadv 
                        category={this.state.category}
                        handleSuccess={(e)=>{this.handleAdd(e)}}
                    />
                    <Table className="tablesorter apply-table" responsive>
                        <thead className="text-primary apply-thead">
                            <tr>
                                <th className="mw-30">Bölüm Adı</th>
                                <th className="mw-30 text-center">Başvuru Sayısı</th>
                                <th className="mw-30 text-center">Aktiflik</th>
                                <th className="mw-10">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.applies.map((item, index) => {
                                return (
                                    <tr className="jobadv-td" colSpan={4} key={index}>
                                        <td className="mw-30">{item.name}</td>
                                        <td className="mw-30 text-center">{item.count}</td>
                                        <td className="mw-30 text-center">
                                            <Form.Check 
                                                type="switch"
                                                id={item.id}
                                                onChange={this.onCheck.bind(this, item.id)}
                                                value={item.active}
                                                defaultChecked={item.active===1 ? true : false}
                                            />
                                        </td>
                                        <td className="mw-10">
                                            <Nav>
                                                <NavLink
                                                    to={{
                                                        pathname:'/admin/basvurular',
                                                        jobid: item.id
                                                    }}
                                                >
                                                    Detay
                                                </NavLink>
                                            </Nav>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </CardBody>
                            
              
            </>
          );
    }
  
}
export default Jobadv;