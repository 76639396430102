import React, { useState } from "react";
import {
  BackgroundColorContext,
  backgroundColors,
} from "contexts/BackgroundColorContext";

import axios from 'axios';

export default function BackgroundColorWrapper(props) {
  
  const [color, setColor] = useState(backgroundColors.color);
  function changeColor(color) {
    setColor(color);
  }

  axios.get('../api/auth/user')
  .then(res => {
    if(res.data.status){
      if(res.data.user.color === '0'){
        changeColor('primary');
      }
      if(res.data.user.color === '1'){
        changeColor('blue');
      }
      if(res.data.user.color === '2'){
        changeColor('green');
      }
    }
  });

  return (
    <BackgroundColorContext.Provider
      value={{ color: color, changeColor: changeColor }}
    >
      {props.children}
    </BackgroundColorContext.Provider>
  );
}
