import React, { Component} from 'react';
import {
    Button,
    Col,
    Row,
    CardBody
  } from "reactstrap";
import TextField from '@mui/material/TextField';
import axios from 'axios';
import NotificationAlert from "react-notification-alert";

var formValues = {
    desc2: [],
    desc3: [],
    questions: [],
};

const OpenForm = (props) => {
    const [opened, setOpened] = React.useState(false );
    const openedHeight = { height: '100%'};
    const closedHeight = { height: 0};
    const handleChange = async (e) => {
        await setOpened(opened ? false : true);
    };
    return (
        <>
            <Row>
                <Col md="12">
                    <Button className="btn-fill" type="onclick" onClick={handleChange}>
                        Yeni İlan Ekle
                    </Button>
                </Col>
                <Col md="12"
                    className="add-new-section d-flex mt-2"
                    style={opened ? openedHeight : closedHeight}
                >
                    {props.items}
                </Col>
            </Row>
        </>
    )
}
/* İşte Yapacakların */
const ActionDesc2List = props => {
    if(props.success){
        props.actionItemsList1.forEach((item, index) => {
            props.deleteActionItemFromState(index);
        })
    }
    const deleteActionItemFromState = index => () => {
        props.deleteActionItemFromState(index);
    };

    return (
            <Row className="mt-2">
                {props.actionItemsList1.map((item, index) => (
                    <Col md="12" className="d-flex mt-1" key={index + 1}>
                        <span className='action-list-marker d-flex'>
                            <a
                                className='mr-2'
                                style={{cursor:'pointer', fontSize:22, color:'red'}}
                                onClick={deleteActionItemFromState(index)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </a>
                            {index + 1}.
                        </span>
                        <Col md="11" className="d-block pl-0">
                            <Col md="6"><a style={{paddingright: '8px', fontWeight: '700'}}>Tr: </a>{item.Inputvalue_tr}</Col>
                            <Col md="6"><a style={{paddingright: '8px', fontWeight: '700'}}>En: </a>{item.Inputvalue_en}</Col>
                        </Col>
                    </Col>

                ))}
            </Row>
    );
}

class Desc2List extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            inputvalue_tr: "",
            inputvalue_en: "",
            ActionItemsList1: []
        };
    }

    addActionItemToState = async (inputvalue_tr, inputvalue_en) => {
        let toBeAddedActionItem = {
            Inputvalue_tr: inputvalue_tr,
            Inputvalue_en: inputvalue_en,
        };
        await this.setState(prevState => ({
            ActionItemsList1: prevState.ActionItemsList1.concat(toBeAddedActionItem)
        }));
            formValues.desc2 = this.state.ActionItemsList1;
    };

    handleChange = event => {
        event.persist();
        this.setState(prevState => ({
            inputvalue_tr: event.target.name === "inputvalue_tr" ? event.target.value : prevState.inputvalue_tr,
            inputvalue_en: event.target.name === "inputvalue_en" ? event.target.value : prevState.inputvalue_en,
        }));
    };
    
    handleSubmission = event => {
        event.preventDefault();
        this.addActionItemToState(this.state.inputvalue_tr, this.state.inputvalue_en);
        this.setState({
            inputvalue_tr: "",
            inputvalue_en: "",
        });
    };

    deleteActionItemFromState = index => {
        const ActionItemsList1 = [...this.state.ActionItemsList1];
        ActionItemsList1.splice(index, 1);
        this.setState({ ActionItemsList1 });
    };

    render() {
        return(
            <>
                <Col md="12" className='addable-from'>
                    <Col md="12" className="d-flex">
                        <TextField
                            className="mt-1 pr-1"
                            label="İşte Yapacakların 🇹🇷"
                            id="inputvalue_tr"
                            name="inputvalue_tr"
                            size="small"
                            onChange={this.handleChange}
                            value={this.state.inputvalue_tr}
                            fullWidth
                        />
                        <TextField
                            className="mt-1 pl-1"
                            label="İşte Yapacakların 🇺🇸"
                            id="inputvalue_en"
                            name="inputvalue_en"
                            size="small"
                            onChange={this.handleChange}
                            value={this.state.inputvalue_en}
                            fullWidth
                        />
                        <Button className="btn-fill ml-2" type="onclick" onClick={this.handleSubmission} style={{overflow:'auto !important'}}>
                            +
                        </Button>
                    </Col>
                    <Col md="12">
                        <ActionDesc2List
                            actionItemsList1={this.state.ActionItemsList1}
                            deleteActionItemFromState={this.deleteActionItemFromState}
                            success={this.props.success}
                        />
                    </Col>
                </Col>
            </>
        );
    }
}
/* //İşte Yapacakların */

/* İstenilen Beceri ve Deneyimler */
const ActionDesc3List = props => {
    if(props.success){
        props.actionItemsList1.forEach((item, index) => {
            props.deleteActionItemFromState(index);
        })
    }
    const deleteActionItemFromState = index => () => {
        props.deleteActionItemFromState(index);
    };

    return (
            <Row className="mt-2">
                {props.actionItemsList1.map((item, index) => (
                    <Col md="12" className="d-flex mt-1" key={index + 1}>
                        <span className='action-list-marker d-flex'>
                            <a
                                className='mr-2'
                                style={{cursor:'pointer', fontSize:22, color:'red'}}
                                onClick={deleteActionItemFromState(index)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </a>
                            {index + 1}.
                        </span>
                        <Col md="11" className="d-block pl-0">
                            <Col md="6"><a style={{paddingright: '8px', fontWeight: '700'}}>Tr: </a>{item.Inputvalue_tr}</Col>
                            <Col md="6"><a style={{paddingright: '8px', fontWeight: '700'}}>En: </a>{item.Inputvalue_en}</Col>
                        </Col>
                    </Col>

                ))}
            </Row>
    );
}

class Desc3List extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            inputvalue_tr: "",
            inputvalue_en: "",
            ActionItemsList1: []
        };
    }

    addActionItemToState = async (inputvalue_tr, inputvalue_en) => {
        let toBeAddedActionItem = {
            Inputvalue_tr: inputvalue_tr,
            Inputvalue_en: inputvalue_en,
        };
        await this.setState(prevState => ({
            ActionItemsList1: prevState.ActionItemsList1.concat(toBeAddedActionItem)
        }));
            formValues.desc3 = this.state.ActionItemsList1;
    };

    handleChange = event => {
        event.persist();
        this.setState(prevState => ({
            inputvalue_tr: event.target.name === "inputvalue_tr" ? event.target.value : prevState.inputvalue_tr,
            inputvalue_en: event.target.name === "inputvalue_en" ? event.target.value : prevState.inputvalue_en,
        }));
    };
    
    handleSubmission = event => {
        event.preventDefault();
        this.addActionItemToState(this.state.inputvalue_tr, this.state.inputvalue_en);
        this.setState({
            inputvalue_tr: "",
            inputvalue_en: "",
        });
    };

    deleteActionItemFromState = index => {
        const ActionItemsList1 = [...this.state.ActionItemsList1];
        ActionItemsList1.splice(index, 1);
        this.setState({ ActionItemsList1 });
    };

    render() {
        return(
            <>
                <Col md="12" className='addable-from'>
                    <Col md="12" className="d-flex">
                        <TextField
                            className="mt-1 pr-1"
                            label="İstenilen Beceri ve Deneyimler 🇹🇷"
                            id="inputvalue_tr"
                            name="inputvalue_tr"
                            size="small"
                            onChange={this.handleChange}
                            value={this.state.inputvalue_tr}
                            fullWidth
                        />
                        <TextField
                            className="mt-1 pl-1"
                            label="İstenilen Beceri ve Deneyimler 🇺🇸"
                            id="inputvalue_en"
                            name="inputvalue_en"
                            size="small"
                            onChange={this.handleChange}
                            value={this.state.inputvalue_en}
                            fullWidth
                        />
                        <Button className="btn-fill ml-2" type="onclick" onClick={this.handleSubmission} style={{overflow:'auto !important'}}>
                            +
                        </Button>
                    </Col>
                    <Col md="12">
                        <ActionDesc3List
                            actionItemsList1={this.state.ActionItemsList1}
                            deleteActionItemFromState={this.deleteActionItemFromState}
                            success={this.props.success}
                        />
                    </Col>
                </Col>
            </>
        );
    }
}
/* //İstenilen Beceri ve Deneyimler */

/* Questions */
const ActionQuestionsList = props => {
    if(props.success){
        props.actionItemsList1.forEach((item, index) => {
            props.deleteActionItemFromState(index);
        })
    }
    const deleteActionItemFromState = index => () => {
        props.deleteActionItemFromState(index);
    };

    return (
            <Row className="mt-2">
                {props.actionItemsList1.map((item, index) => (
                    <Col md="12" className="d-flex mt-1" key={index + 1}>
                        <span className='action-list-marker d-flex'>
                            <a
                                className='mr-2'
                                style={{cursor:'pointer', fontSize:22, color:'red'}}
                                onClick={deleteActionItemFromState(index)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </a>
                            {index + 1}.
                        </span>
                        <Col md="11" className="d-block pl-0">
                            <Col md="6"><a style={{paddingright: '8px', fontWeight: '700'}}>Tr: </a>{item.Inputvalue_tr}</Col>
                            <Col md="6"><a style={{paddingright: '8px', fontWeight: '700'}}>En: </a>{item.Inputvalue_en}</Col>
                        </Col>
                    </Col>
                ))}
            </Row>
    );
}

class QuestionsList extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            inputvalue_tr: "",
            inputvalue_en: "",
            ActionItemsList1: []
        };
    }

    addActionItemToState = async (inputvalue_tr, inputvalue_en) => {
        let toBeAddedActionItem = {
            Inputvalue_tr: inputvalue_tr,
            Inputvalue_en: inputvalue_en,
        };
        await this.setState(prevState => ({
            ActionItemsList1: prevState.ActionItemsList1.concat(toBeAddedActionItem)
        }));
            formValues.questions = this.state.ActionItemsList1;
    };

    handleChange = event => {
        event.persist();
        this.setState(prevState => ({
            inputvalue_tr: event.target.name === "inputvalue_tr" ? event.target.value : prevState.inputvalue_tr,
            inputvalue_en: event.target.name === "inputvalue_en" ? event.target.value : prevState.inputvalue_en,
        }));
    };
    
    handleSubmission = event => {
        event.preventDefault();
        this.addActionItemToState(this.state.inputvalue_tr, this.state.inputvalue_en);
        this.setState({
            inputvalue_tr: "",
            inputvalue_en: "",
        });
    };

    deleteActionItemFromState = index => {
        const ActionItemsList1 = [...this.state.ActionItemsList1];
        ActionItemsList1.splice(index, 1);
        this.setState({ ActionItemsList1 });
    };

    render() {
        return(
            <>
                <Col md="12" className='addable-from'>
                    <Col md="12" className="d-flex">
                        <TextField
                            className="mt-1 pr-1"
                            label="Sorular 🇹🇷"
                            id="inputvalue_tr"
                            name="inputvalue_tr"
                            size="small"
                            onChange={this.handleChange}
                            value={this.state.inputvalue_tr}
                            fullWidth
                        />
                        <TextField
                            className="mt-1  pl-1"
                            label="Sorular 🇺🇸"
                            id="inputvalue_en"
                            name="inputvalue_en"
                            size="small"
                            onChange={this.handleChange}
                            value={this.state.inputvalue_en}
                            fullWidth
                        />
                        <Button className="btn-fill ml-2" type="onclick" onClick={this.handleSubmission} style={{overflow:'auto !important'}}>
                            +
                        </Button>
                    </Col>
                    <Col md="12">
                        <ActionQuestionsList
                            actionItemsList1={this.state.ActionItemsList1}
                            deleteActionItemFromState={this.deleteActionItemFromState}
                            success={this.props.success}
                        />
                    </Col>
                </Col>
            </>
        );
    }
}
/* //Questions */
class Newadv extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            name_tr: '',
            name_en: '',
            desc_tr: '',
            desc_en: '',
            success: false
        };
    }
    onValueChange = e => {
        this.setState(prevState =>({
            name_tr: e.target.name === "name_tr" ? e.target.value : prevState.name_tr,
            name_en: e.target.name === "name_en" ? e.target.value : prevState.name_en,
            desc_tr: e.target.name === "desc_tr" ? e.target.value : prevState.desc_tr,
            desc_en: e.target.name === "desc_en" ? e.target.value : prevState.desc_en,
        }));
    };
    handleSubmit = (e) => {
        e.preventDefault();
        var valueData = new FormData();
        this.props.handleSuccess(this.props.category);
        valueData.append('desc2', JSON.stringify(formValues.desc2));
        valueData.append('desc3', JSON.stringify(formValues.desc3));
        valueData.append('questions', JSON.stringify(formValues.questions));
        valueData.append('name_tr', this.state.name_tr);
        valueData.append('name_en', this.state.name_en);
        valueData.append('desc_tr', this.state.desc_tr);
        valueData.append('desc_en', this.state.desc_en);
        valueData.append('category', this.props.category);

        axios.post('../api/auth/applies-create', valueData)
        .then(async res => {
            if(res.data.status === true){
                this.handleNotificate('success', res.data.msg);
                await this.setState({
                    name_tr: '',
                    name_en: '',
                    desc_tr: '',
                    desc_en: '',
                    success: true
                });
                this.setState({
                    success: false
                })
            }
            else{
                this.handleNotificate('danger', res.data.msg);
            }
        })
        
    }
    handleNotificate = (s, msg) => {
        msg.forEach(item => {
          this.refs.notify.notificationAlert({
            place: 'br',
            message: item,
            type: s,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 5
          });
        });
    }
    render(){
        return (
            <>
                <NotificationAlert ref="notify" zIndex={9999}/>
                <CardBody>
                    <OpenForm
                        success={this.state.success}
                        items={(
                            <>
                            <Row>
                                <Col md="12" className="d-flex p-0">
                                    <Col className="mt-2 pr-1 pl-0" md="6">
                                        <TextField
                                            label={'Başlık 🇹🇷'}
                                            id="filled-size-small"
                                            name="name_tr"
                                            size="small"
                                            value={this.state.name_tr}
                                            onChange={this.onValueChange}
                                            fullWidth
                                        />
                                    </Col>
                                    <Col className="mt-2 pl-1 pr-0" md="6">
                                        <TextField
                                            label={'Başlık 🇺🇸'}
                                            id="filled-size-small"
                                            name="name_en"
                                            size="small"
                                            value={this.state.name_en}
                                            onChange={this.onValueChange}
                                            fullWidth
                                        />
                                    </Col>
                                </Col>
                                <Col md="12" className="d-flex p-0">
                                    <Col className="mt-2 mb-2 pr-1 pl-0" md="6">
                                        <TextField
                                            label={'Açıklama 🇹🇷'}
                                            id="filled-size-small"
                                            name="desc_tr"
                                            size="small"
                                            value={this.state.desc_tr}
                                            onChange={this.onValueChange}
                                            fullWidth
                                        />
                                    </Col>
                                    <Col className="mt-2 mb-2 pl-1 pr-0" md="6">
                                        <TextField
                                            label={'Açıklama 🇺🇸'}
                                            id="filled-size-small"
                                            name="desc_en"
                                            size="small"
                                            value={this.state.desc_en}
                                            onChange={this.onValueChange}
                                            fullWidth
                                        />
                                    </Col>
                                </Col>

                                <Desc2List
                                    success={this.state.success}
                                />

                                <Desc3List
                                    success={this.state.success}
                                />

                                <QuestionsList
                                    success={this.state.success}
                                />

                                <Button 
                                    className="btn-fill col-2 ml-auto" 
                                    type="submit"
                                    onClick={this.handleSubmit}
                                >
                                    Kaydet
                                </Button>

                            </Row>
                                
                            </>
                        )}
                    >
                    </OpenForm>
                </CardBody>
            </>
        )
    }
}

export default Newadv;