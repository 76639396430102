import Form from 'react-bootstrap/Form';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import axios from 'axios';
import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import JoditEditor from "jodit-react";

class ExtraInput extends Component {
  constructor(props){
    super(props);
    this.editor = React.createRef(null);
    let href = '../api/auth/expage/' + props.id;
    axios.get(href)
    .then(res => {
      this.setState({
        name: res.data.values.title_tr,
        nameeng: res.data.values.title_en,
        css: res.data.values.css,
        html: res.data.values.html,
        js: res.data.values.js
      })
    });
  }
  state = {
    id: this.props.id,
    name: '',
    nameeng: '',
    css: '',
    html: '',
    js: '',
    url: "http://localhost:8000/expage/" + this.props.id,
  }
  onChangeCss = (e) => {
    this.setState({
      css: e.target.value
    })
  }
  onChangeJs = (e) => {
    this.setState({
      js: e.target.value
    })
  }

  handleSubmit = (e) => {
    var data = new FormData;
    data.append('css', this.state.css);
    data.append('html', this.state.html);
    data.append('js', this.state.js);
    data.append('id', this.state.id);
    data.append('name', this.state.name);
    data.append('name_eng', this.state.nameeng);
    let href = '../api/auth/update-expage/' + this.state.id;
    axios.post(href, data)
    .then(res => {
      if(res.data.status){
        this.refs.notify.notificationAlert({
          place: 'br',
          message: res.data.message,
          type: 'success',
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 5
        });
        this.setState({
          url: ''
        })
        this.setState({
          url: "http://localhost:8000/expage/" + this.props.id,
        })
      }
    });
  }

  render(){
    return (
      <>
        <div className="content">
          <Row>
            <NotificationAlert ref="notify" zIndex={9999}/>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="title">Ekstra Sayfa</h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col 
                      md="12"
                      className="mt-auto mb-auto"
                    >
                      <Col className="d-flex ml-0 pl-0 extra-page-inputs">
                        <Col className="mb-md-1" md="12">
                          <Form.Group className="mb-3 extra-page-inputs" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>CSS</Form.Label>
                            <Form.Control 
                              rows={10}
                              as="textarea" 
                              className="form-with-value"
                              value={this.state.css}
                              onChange={this.onChangeCss}
                            />
                          </Form.Group>
                        </Col>
                      </Col>
                      <Col className="d-flex ml-0 pl-0">
                        <Col className="mb-md-2" md="12">
                          <JoditEditor
                            tabIndex={5}
                            darkMode={true}
                            ref={this.editor}
                            value={this.state.html}
                            config={{readonly: false}}
                            onBlur={newContent => this.setState({html: newContent})} 
                            onChange={newContent => {}}
                          />
                        </Col>
                      </Col>
                      <Col className="d-flex ml-0 pl-0">
                        <Col className="mb-md-2" md="12">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Javascript</Form.Label>
                            <Form.Control 
                              as="textarea" 
                              className="form-with-value"
                              value={this.state.js}
                              onChange={this.onChangeJs}
                            />
                          </Form.Group>
                        </Col>
                      </Col>
                    </Col>
                  </Row>
                  <Button 
                    className="btn-fill ml-3" 
                    type="onclick"
                    onClick={this.handleSubmit}
                  >
                    Gönder
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <iframe src={this.state.url}
              style={{width:'100%', height: 500, border: 0, borderRadius: 4, overflow: 'hidden'}}
            ></iframe>
          </Row>
        </div>
      </>
    );
  }
}
class Extra extends Component {
  constructor(props){
    super(props);
  }

  render(){
    return (
      <>
        <ExtraInput
          id={this.props.id}
        />
      </>
    );
  }
}
export default Extra;
