import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Table,
    Row,
    Col,
    Button,
    CardTitle,
  } from "reactstrap";
  import axios from 'axios';
  import Accordion from '@mui/material/Accordion';
  import AccordionDetails from '@mui/material/AccordionDetails';
  import AccordionSummary from '@mui/material/AccordionSummary';

class Messages extends Component {
    constructor(props){
        super(props);
        this.state = {
            messages: [],
            exp: 'expanded-',
            modal: false,
            msg_header: 'Silmek',
            to: '',
            id: '',
        }
        axios.get('../api/auth/messages')
        .then(res => {
            this.setState({
                messages: res.data.data,
            })
        })
    }

    handleChange = (i, e, isExpanded) => {
        if(!e.target.classList.contains('complated-btns')){
            if(isExpanded){
                this.setState({
                    exp: 'expanded-' + i
                });
            }
            else {
                this.setState({
                    exp: 'expanded-'
                });
            }
        }
    }
    onCheckok = (id) => {
        let href = '../api/auth/message-update/' + id,
            data = new FormData;
        data.append('id', id);
        axios.post(href, data)
        .then(res => {
            if(res.data.status){
                this.setState({
                    messages: res.data.data,
                });
            }
        });
    }
    onCheckdis = (id) => {
        let href = '../api/auth/message-remove/' + id,
            data = new FormData;
        data.append('id', id);
        axios.post(href, data)
        .then(res => {
            if(res.data.status){
                this.setState({
                    messages: res.data.data,
                });
            }
        });
    }
    onModal = (status, to, id, e) => {
        this.setState({
            modal: status,
            msg_header: to === 'update' ? 'onaylamak' : 'silmek',
            to: to,
            id, id
        })
    }
    onSubmit = async (to, id) => {
        if(to === 'update'){
            await this.onCheckok(id);
            this.setState({
                modal: false,
            })
        }
        if(to === 'remove'){
            await this.onCheckdis(id);
            this.setState({
                modal: false,
            })
        }
    }
    onCancel = () => {
        this.setState({
            modal: false,
        });
    }
    render(){
        return(
            <>
                <div className="content">
                    <Col md="12" className={(this.state.modal ? 'opened' : '' )+ " t-modal-body"}>
                        <Card className=" transaction-modal">
                            <CardHeader>
                                Mesajı {this.state.msg_header} istediğinizden emin misiniz?
                            </CardHeader>
                            <CardBody className='mt-4 d-flex'>
                                <Button 
                                    className="btn-fill ml-3" 
                                    type="onclick"
                                    onClick={this.onSubmit.bind(this, this.state.to, this.state.id)}
                                >
                                    Onayla
                                </Button>
                                <Button 
                                    className="btn-fill ml-3" 
                                    type="onclick"
                                    onClick={this.onCancel.bind(this, this.state.to, this.state.id)}
                                >
                                    Vazgeç
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                    
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter apply-table" responsive>
                                        <thead className="text-primary apply-thead">
                                            <tr>
                                                <th className="mw-30">User ID</th>
                                                <th className="mw-30">Amount</th>
                                                <th className="mw-30">Currency</th>
                                                <th className="mw-10">Complated</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.messages.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="apply-td" colSpan={7}>
                                                            <Accordion 
                                                                expanded={this.state.exp === ('expanded-' + index)} 
                                                                onChange={this.handleChange.bind(this, index)
                                                            }>
                                                                <AccordionSummary
                                                                    aria-controls="panel1bh-content"
                                                                    id="panel1bh-header"
                                                                >
                                                                    <td className="mw-30">{item['user_id']}</td>
                                                                    <td className="mw-30">{item['amount']}</td>
                                                                    <td className="mw-30">{item['currency']}</td>
                                                                    <td className="mw-10 messages-buttons">
                                                                        <a class="complated-btns" onClick={this.onModal.bind(this, true, 'update', item['id'])} href="#" role="button">
                                                                            <i class="complated-btns tim-icons sidebar-icons text-center icon-check-2"></i>
                                                                        </a>
                                                                        <a class="complated-btns" onClick={this.onModal.bind(this, true, 'remove', item['id'])} href="#" role="button">
                                                                            <i class="complated-btns tim-icons sidebar-icons text-center icon-trash-simple"></i>
                                                                        </a>
                                                                    </td>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Row>
                                                                        <Card>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h4">Details</CardTitle>
                                                                            </CardHeader>
                                                                            <CardBody>
                                                                                <Col className="user-inf">
                                                                                    <p className="user-title">User ID: </p>
                                                                                    <p className="user-explain">{item['user_id']}</p>
                                                                                </Col>
                                                                                <Col className="user-inf">
                                                                                    <p className="user-title">From Address: </p>
                                                                                    <p className="user-explain">{item['from_address']}</p>
                                                                                </Col>
                                                                                <Col className="user-inf">
                                                                                    <p className="user-title">TXN Code: </p>
                                                                                    <p className="user-explain">{item['txn_code']}</p>
                                                                                </Col>
                                                                                <Col className="user-inf">
                                                                                    <p className="user-title">Amount: </p>
                                                                                    <p className="user-explain">{item['amount']}</p>
                                                                                </Col>
                                                                                <Col className="user-inf">
                                                                                    <p className="user-title">Network: </p>
                                                                                    <p className="user-explain">{item['network']}</p>
                                                                                </Col>
                                                                                <Col className="user-inf">
                                                                                    <p className="user-title">Currency: </p>
                                                                                    <p className="user-explain">{item['currency']}</p>
                                                                                </Col>
                                                                                <Col className="user-inf">
                                                                                    <p className="user-title">Message: </p>
                                                                                    <p className="user-explain">{item['message']}</p>
                                                                                </Col>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Row>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default Messages;