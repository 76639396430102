import axios from 'axios';
import React, { Component } from 'react';
import Applies from '../components/Jobs/Applies';
import {
    Row,
    Col,
    Card,
    CardBody
  } from "reactstrap";
import Jobadv from 'components/Jobs/Job-adv';

class Jobs extends Component {

    render(){
        return(
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Jobadv/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default Jobs;