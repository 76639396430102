import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            logout: this.props.logout
        };
    }

    handleLogout = () => {
        this.revokeAccessToken();
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('userData');
        localStorage.removeItem('loggedInTime');
        this.props.history.push('/login');
    };

    componentDidUpdate = () => {
        this.updateLocalDetails();
    };

    UNSAFE_componentWillMount = () => {
        this.updateLocalDetails();
    };

    updateLocalDetails = () => {
        let userData = localStorage.getItem('userData');
        if (userData) {
            userData = JSON.parse(userData);
            if (Object.keys(userData).length > 0) {
                let lastLoggedInTime = localStorage.getItem('loggedInTime');
                let now = (new Date()).getTime();
                let rememberMe = localStorage.getItem('isRemember');
                if ((rememberMe !== 'true' && (now - lastLoggedInTime) > (10000*60*24)) || this.props.logout) // if user doesn't perform any activity in 24 minutes than log him/her out.
                    this.handleLogout();

                if (axios.defaults.headers.common['Authorization'] == undefined) {
                    axios.defaults.headers.common['Accept'] = 'application/json';
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ userData.access_token;
                }

                localStorage.setItem('loggedInTime', (new Date()).getTime());

                if (this.state.name == '')
                    this.setState({name: userData.name || 'Anonymous'})
            } else {
                this.handleLogout();
            }
        }
    };

    revokeAccessToken = () => {
        axios.get('../api/logout', {}).then((response) => {
            console.log(`Logout successfully.`, 'Success');
        }).catch((err) => {
            console.log(`Internal server error occurred, Please try later.`, 'Error');
        });
    };

    render() {
        let isAuthenticated = localStorage.getItem('isAuthenticated');
        
        return (
            <>
                {isAuthenticated && isAuthenticated === 'true' && (
                    <>
                    </>
                )}
            </>
        );
    }
}

export default withRouter(Navigation);
