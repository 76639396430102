import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Send from "./Add";
import {
  Table,
} from "reactstrap";

export default function Details(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Table className="tablesorter" responsive>
        <thead className="text-primary detail-table">
          <tr>
            {props.headers.map((item, index) => (
              item === 'background' ? '' :
              (<th key={index} className={"mw-20"}>{item === 'Resim' ? '' : item}</th>)
            ))}
          </tr>
        </thead>
        <tbody>
          {props.values.map((item, ind) => (
            <tr key={ind}>
              <td colSpan={props.headers.length + 1}>
                <Accordion expanded={expanded === 'panel1'+ind} onChange={handleChange('panel1' + ind)}>
                  <AccordionSummary
                    expandIcon={<a className="detail-buttons"><i className="tim-icons icon-pencil mx-1" /></a>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                  <table className="w-100">
                    <tbody>
                      <tr>
                        {props.dbValues.map((element, index) => (
                          element === 'image' && item[element] != undefined ? 
                          <td key={index} className={"mw-20"}>
                            <img className="img-fluid" src={'https://mancgames.com/storage/img/' + item[element]}/> 
                          </td>
                          :
                        ( element === 'background' ? '' :
                          <td key={index} className={"embed-td mw-20"}>
                              {item[element]}
                          </td>)
                        ))}
                      </tr>
                    </tbody>
                  </table>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Send 
                      index={ind}
                      handleClick={props.handleClick}
                      values={props.values}
                      dbValues={props.dbValues}
                      updateHref={props.updateHref + item.id}
                      edit={true}
                      headers={props.headers}
                      elementId={item.id}
                      imgValue={props.imgValue}
                      getHref={props.getHref}
                    >
                    </Send>
                  </AccordionDetails>
                </Accordion>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}