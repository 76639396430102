import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import Navigation from "./partials/Navigation";
import PrivateRoute from "./partials/PrivateRoute";
import Login from "./auth/Login";
import AdminLayout from "layouts/Admin/Admin.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "assets/css/colors.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


export default class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      logout: false,
    }
  }
  render(){
    return(
        <BrowserRouter>
          <Navigation 
            logout={this.state.logout}
          />
          <ThemeContextWrapper>
            <BackgroundColorWrapper>
              <Switch>
                <PrivateRoute 
                  component={AdminLayout}
                  handleClick={async (prop)=>{
                    await this.setState({
                      logout: prop.logout
                    });
                    this.setState({
                      logout: false
                    })
                  }}
                />
                <Route component={Login}/>
              </Switch>
            </BackgroundColorWrapper>
          </ThemeContextWrapper>
        </BrowserRouter>
    )
  }
}
setTimeout(() => {
  ReactDOM.render(
    <>
      <App/>
    </>,
    document.getElementById("root")
  );
}, 500);
 
  

