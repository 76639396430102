import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import {
    Button,
    Row,
    Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import DefaultImg from "./DefaultImage";

class Bannerform extends Component {
    constructor(props){
        super(props);
        this.state = {
            text_tr: this.props.values.text_tr,
            text_en: this.props.values.text_en,
            img_m_tr: null,
            img_m_tr_Pre: this.props.values.img_m_tr,
            img_d_tr: null,
            img_d_tr_Pre: this.props.values.img_m_tr,
            message: [],
            status: null,
            values: [],
            action: this.props.action
        }
    }
    onValue1Change = e => {
        this.setState({
            text_tr: e.target.value
        });
    };
    onValue2Change = e => {
        this.setState({
            text_en: e.target.value
        });
    };
    onFileChange = async e => {
        let state_name = e.target.name,
            prename = state_name + '_Pre';
        await this.setState({
            [state_name]: e.target.files[0]
        });
        var formData = new FormData();

        formData.append(state_name, this.state[state_name]);
        await axios.post('../api/auth/banner-img-upload/' + state_name, formData)
        .then(res => {
            this.setState({
                [prename]: res.data.name,
                type: res.data.status ? 'success' : 'danger',
                message: res.data.msg,
            });
            this.handleNotificate(this.state.message);
        })
    };
    handleSubmit = async (e) => {
        e.preventDefault();
        var formData = new FormData();

        formData.append('text_tr', this.state.text_tr);
        formData.append('text_en', this.state.text_en);
        formData.append('img_m_tr', this.state.img_m_tr_Pre);
        formData.append('img_d_tr', this.state.img_d_tr_Pre);
        let url = this.state.action == 'update' ? ('../api/auth/update-Banner/' + this.props.values.id) : '../api/auth/add-Banner';
        await axios.post(url, formData)
        .then(res => {
            if(res.data.status == true && this.state.action === 'create'){
                this.setState({
                    type: 'success',
                    text_tr: '',
                    text_en: '',
                    img_m_tr: null,
                    img_d_tr: null,
                    img_m_tr_Pre: '',
                    img_d_tr_Pre: '',
                    values: res.data.values,
                    status: res.data.status,
                    message: res.data.msg,
                });
                this.props.handleSuccess(res.data.values);
            }
            else if(this.state.action === 'update'){
                this.setState({
                    type: 'success',
                    message: res.data.msg,
                });
                this.props.handleSuccess(res.data.values);
            }
            else{
                this.setState({
                    type: 'danger',
                    message: res.data.msg,
                });
            }
            this.handleNotificate(this.state.message);
        });
    }
    handleNotificate = (messages) => {
        messages.forEach(item => {
            this.refs.notify.notificationAlert({
                place: 'br',
                message: item,
                type: this.state.type,
                icon: "now-ui-icons ui-1_bell-53",
                autoDismiss: 5
            });
        });
    }
    render(){
        return(
            <>
                <NotificationAlert ref="notify" zIndex={9999}/>
                <Row>
                    <Col md="6" className="mt-auto mb-auto">
                        <Col className="px-3 mb-2 mt-2" md="12">
                            <TextField
                                label={'Metin 🇹🇷'}
                                id="filled-size-small"
                                size="small"
                                className="mb-2"
                                value={this.state.text_tr}
                                onChange={this.onValue1Change}
                                placeholder={''}
                                fullWidth
                            />
                        </Col>
                        <Col className="px-3 mb-2 mt-2" md="12">
                            <TextField
                                label={'Metin 🇺🇸'}
                                id="filled-size-small"
                                size="small"
                                className="mb-2"
                                value={this.state.text_en}
                                onChange={this.onValue2Change}
                                placeholder={''}
                                fullWidth
                            />
                        </Col>
                    </Col>
                    <Col className="d-flex" md="6">
                        <Col md="3">
                            <Form.Group className="mb-3 mobile-adv">
                                <img className="ad-m-mockup" src="https://mancgames.com/storage/img/phone_mockup.png"></img>
                                {this.state.img_m_tr_Pre != '' ? 
                                    <img className="ad-m-preview" src={"https://mancgames.com/storage/img/" + this.state.img_m_tr_Pre}></img>
                                :
                                    <DefaultImg 
                                        width={900} 
                                        height={1600}
                                        position={'absolute'}
                                        realwidth={'90%'}
                                    />
                                }
                                
                                <Form.Control 
                                    type="file"
                                    onChange={this.onFileChange}
                                    name="img_m_tr"
                                />
                            </Form.Group>
                        </Col>
                        <Col md="9">
                        <Form.Group className="mb-3 mobile-adv">
                            <img className="ad-d-mockup" src="https://mancgames.com/storage/img/laptop_mockup.png"></img>
                            {this.state.img_d_tr_Pre != '' ? 
                                <img className="ad-d-preview" src={"https://mancgames.com/storage/img/" + this.state.img_d_tr_Pre}></img>
                            :
                                <DefaultImg 
                                    width={1800} 
                                    height={800}
                                    position={'absolute'}
                                    realwidth={'70%'}
                                />
                            }
                            <Form.Control 
                                type="file"
                                onChange={this.onFileChange}
                                name="img_d_tr"
                            />
                            </Form.Group>
                        </Col>
                    </Col>
                </Row>
                <Button 
                    className="btn-fill ml-3" 
                    type="submit"
                    onClick={this.handleSubmit}
                >
                    Gönder
                </Button>
            </>
        )
    }
}

export default Bannerform;